import axios from "axios";

export const googleMapsApiKey = "AIzaSyB6xJuJG9zvaK8yS9ExgJBkquDloinsKzc";

export const fetchCities = async () => {
  try {
    const response = await axios.post(
      `https://countriesnow.space/api/v0.1/countries/cities`,
      {
        country: "France",
      }
    );
    return response.data?.data.map((e) => ({
      value: e,
      label: e,
    }));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error fetching cities:", error.message);
      return []; // or provide a fallback list of cities
    } else {
      console.error("Unexpected error:", error);
      throw error;
    }
  }
};
