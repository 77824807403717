import dateIcon from "../../assets/icons/generalePage/calendar.svg";
import { Button } from "@material-tailwind/react";
import DataTable from "react-data-table-component";
import visit from "../../assets/icons/sidebar/visit.svg";
import LoadingSpinner from "../loadingSpinner";
import { DateRange } from "react-date-range";
import { useEffect, useState } from "react";
import moment from "moment";
import Payment from "../../models/payment";
import Visit from "../../models/Visit";
import exportIcon from "../../assets/icons/generalePage/download.svg";
import { CSVLink } from "react-csv";
import fr from "date-fns/locale/fr";
import clearFilter from "../../assets/icons/generalePage/clearFilter.png";












export default function VisitsStats() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [visibleCalender, setVisibleCalender] = useState(false);
    const [dateFilter, setDateFilter] = useState({
        startDate: null,
        endDate: null,
        key: "selection",
    });

    const handleSelect = (ranges) => {
        setDateFilter(ranges.selection);
        setVisibleCalender(false);
        getData(ranges.selection.startDate, ranges.selection.endDate);
    };



    const getData = async (start = null, end = null) => {
        setData([]);
        setLoading(true);
        let newData = [];
        const payments = await Payment.getAllP();
        const visits = await Visit.getAllVs();


        let filteredpayments = payments.docs
        if (start && end) {
            filteredpayments = payments.docs.filter((payment) => {
                start = moment(start).format("YYYY-MM-DD");
                end = moment(end).format("YYYY-MM-DD");
                return moment(payment.data().createdAt.toDate()).isBetween(start, end, 'days', '[]');
            });
        }

        visits.docs.forEach((visit) => {
            let visitPayments = [];
            filteredpayments.forEach((payment) => {
                if (payment.data().visitsId === visit.data().id) {
                    visitPayments.push(payment);
                }
            });
            if (visitPayments.length === 0) {
                return;
            }

            newData.push({
                id: visit.id,
                name: visit.data().name,
                nbVente: visitPayments.length,
                dernierVente: moment(visitPayments[visitPayments.length - 1].data().createdAt.toDate()).format("DD/MM/YYYY HH:mm"),
                montantTotal: visitPayments.reduce((a, b) => a + b.data().amount, 0)
            });
        });

        setLoading(false);
        setData(newData);

    }








    useEffect(() => {
        getData();

    }, []);

    const columns = [
        {
            name: 'Nom de visit',
            selector: row => row.name,
            cell: (row) => (
                <div className="flex group flex-row gap-2 items-center justify-between overflow-visible">
                    <img src={visit} className="w-10 h-10 rounded-full aspect-square bg-amber-400 p-1" />
                    <span className="bg-gray-800 absolute left-14 opacity-0 group-hover:opacity-100 transform transition-all duration-200 delay-300 text-white p-3 rounded-xl">{row.name}</span>
                    <p className="text-gray-700 font-semibold">{row.name}</p>
                </div>

            ),

            left: true,


        },
        {
            name: 'Nombre de vente',
            selector: row => row.nbVente,
            sortable: true,
            right: true,
        },
        {
            name: 'Total Montant (en €)',
            selector: row => row.montantTotal,
            cell: (row) => (
                <p className="text-gray-700 font-semibold">{row.montantTotal} €</p>
            ),

            sortable: true,
            right: true,

        },
        {
            name: 'Date de dernier vente',
            selector: row => row.dernierVente,
            sortable: true,
            right: true,
        },
    ];

    return (
        <div className={"w-full mx-auto relative  flex flex-col gap-5 justify-between items-center pt-4 p-3 lg:p-[3vw] bg-white rounded-3xl shadow-2xl  mb-5"}>
            <div className={visibleCalender ? "absolute w-full  z-20 h-full" : "hidden "} onClick={e => setVisibleCalender(false)}></div>
            <div className="w-full relative flex  flex-row items-start justify-between">
                <h1 className="font-semibold text-left w-full text-gray-700 text-3xl font-poppins">Statistiques de Ventes par Visite</h1>
                <div
                    className={`${visibleCalender ? "block" : "hidden"
                        } absolute -top-[80%] lg:right-[10%] right-1 border-2 border-gray-300  bg-white shadow-2xl shadow-gray-400 rounded-2xl overflow-hidden z-50`}
                >

                    {
                        (dateFilter.startDate && dateFilter.endDate) &&
                        <Button
                            id="dateRange"
                            onClick={() => { setDateFilter({ startDate: null, endDate: null, key: "selection" }); getData(); setVisibleCalender(false) }}
                            variant="text"
                            className="rounded-none  bg-red-100/25  hover:bg-red-300/25  flex flex-row  justify-center items-center gap-3 w-full text-red-500 p-3 md:px-4 md:py-3 mr-2  font-semibold"
                        >
                            <img src={clearFilter} className="w-5" alt="" />
                            Cancel
                        </Button>
                    }
                    <DateRange
                        locale={fr}
                        ranges={[dateFilter]}
                        onChange={handleSelect}
                    />
                </div>

                <div className=" flex-row flex gap-1 lg:gap-3">
                    <CSVLink
                        disabled={true}
                        data={data.map((item) => {
                            return {
                                id: item.id,
                                nom: item.name,
                                nombre_de_Vente: item.nbVente,
                                montant: item.montant,
                                total_de_vente: item.totalVente,
                                date_dernier_vente: item.dernierVente,
                            };
                        })}
                        filename={"statistics-de-ventes-(" + ((dateFilter.startDate && dateFilter.endDate) ? `${moment(dateFilter.startDate).format("DD/MM/YYYY")}-${moment(dateFilter.endDate).format("DD/MM/YYYY")})` : "de tous les temps)") + ".csv"}
                        className="rounded-xl w-14 md:w-40 flex flex-row justify-around items-center gap-2 bg-blue-100 hover:bg-blue-200 px-4 py-3 transform mr-2 text-gray-800 font-semibold"
                    >
                        <img className="w-6" src={exportIcon} alt="export icon" />

                        <span className="hidden md:block">Telécharger</span>
                    </CSVLink>

                    <Button
                        id="dateRange"
                        onClick={() => { setVisibleCalender(!visibleCalender) }}
                        variant="outlined"
                        className="rounded-xl w-14 border-2 hover:bg-gray-300 flex flex-row  justify-around md:w-60 aspect-square md:aspect-auto  items-center gap-2 border-gray-300 md:p-3 md:px-4 md:py-3 mr-2 text-gray-800 font-semibold"
                    >
                        <img
                            id="dateRange"
                            className="w-6"
                            src={dateIcon}
                            alt="export icon"
                        />
                        <span
                            id="dateRange"
                            className="hidden md:block w-40 font-bold lg:text-xs"
                        >
                            {(dateFilter.startDate && dateFilter.endDate) ? `${moment(dateFilter.startDate).format("DD/MM/YYYY")}-${moment(dateFilter.endDate).format("DD/MM/YYYY")}` : "Filter par date"}
                        </span>
                    </Button>
                </div>
            </div>
            {
                loading ? <LoadingSpinner /> :
                    <>

                        <div className="w-full rounded-lg overflow-hidden">

                            <DataTable
                                noDataComponent={
                                    (
                                        <div className="flex flex-row gap-5 h-40 justify-center items-center text-xl">
                                            <svg width="40px" height="40px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="#999999" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>location-not-found-solid</title> <g id="Layer_2" data-name="Layer 2"> <g id="invisible_box" data-name="invisible box"> <rect width="48" height="48" fill="none"></rect> </g> <g id="icons_Q2" data-name="icons Q2"> <path d="M24,2C14.1,2,7,10.1,7,20S18.5,41.3,22.6,45.4a1.9,1.9,0,0,0,2.8,0C29.5,41.3,41,30.1,41,20S33.9,2,24,2Zm0,8a8.7,8.7,0,0,1,4.8,1.4L16.4,23.8A8.7,8.7,0,0,1,15,19,9,9,0,0,1,24,10Zm0,18a8.7,8.7,0,0,1-4.8-1.4L31.6,14.2A8.7,8.7,0,0,1,33,19,9,9,0,0,1,24,28Z"></path> </g> </g> </g></svg>
                                            Aucun résultat trouvé
                                        </div>
                                    )
                                }
                                persistTableHead
                                className="w-full"
                                columns={columns}
                                data={data}
                                pagination
                                paginationPerPage={5}
                                paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                                customStyles={{
                                    headCells: {
                                        style: {
                                            borderRaduis: "20px",
                                            FontFace: "Poppins",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            paddingLeft: "8px",
                                            paddingRight: "8px",
                                            color: "white",
                                            backgroundColor: "#1F2937",
                                        },
                                    },
                                    cells: {
                                        style: {
                                            overflow: "visible",
                                        }
                                    },
                                    rows: {
                                        style: {
                                            backgroundColor: "#f7f7f7"
                                        }
                                    },
                                    noData: {
                                        style: {
                                            backgroundColor: "#f7f7f7"
                                        }
                                    }


                                }}

                            >

                            </DataTable>

                        </div>
                    </>
            }


        </div>





    )
}