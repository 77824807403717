// import app from "./firebase";
import { db } from "./firebase";
import { collection, onSnapshot, deleteDoc, doc, addDoc, updateDoc, getDoc, query, where, getDocs } from "firebase/firestore";


export function getAll(collectionName, callback) {
    const query = collection(db, collectionName);
    const unsubscribe = onSnapshot(query, (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        callback(data);
    });
    return unsubscribe;
}

export function createOne(collectionName, data) {
    return addDoc(collection(db, collectionName), data);
}

export function updateOne(collectionName, id, data) {
    return updateDoc(doc(db, collectionName, id), data);
}

export function deleteOne(collectionName, id) {
    return deleteDoc(doc(db, collectionName, id));
}

export function getDocuments(collectionName, querySearch) {
    let q;
    if (querySearch !== undefined) {
        q = query(collection(db, collectionName), ...querySearch);
    } else {
        q = query(collection(db, collectionName));
    }
    return getDocs(q)
}

export function getDocument(collectionName, id) {
    const docRef = doc(db, collectionName, id);
    return getDoc(docRef);
}
