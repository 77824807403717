import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import User from "../models/User";
import { FiEdit2, FiTrash2, FiEye } from "react-icons/fi";
import SuppDialog from "./suppDialog";
import AlertContext from "../context/AlertContext/allertContext";
import blockIcon from "../assets/icons/interdit-icon.png";
import enableIcon from "../assets/icons/enable.png";
import showIcon from "../assets/icons/show.svg";
import { deleteFile } from "../api/fileService";

import LoadingSpinner from "./loadingSpinner";

import deleteIcon from "../assets/icons/delete.svg";
import Payment from "../models/payment";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import UserBlockModal from "./UserBlockModal";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../utils/firebase";
import Admin, { ROLE_SUPER_ADMIN } from "../models/Admin";

const UsersTable = () => {
  const [data, setData] = useState([]);
  const [payments, setPayments] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);

  const nav = useNavigate();

  const [loding, setLoding] = useState(true);

  const [open, setIsOpen] = useState(false);

  const [openBlock, setOpenBlock] = useState(false);

  const [selectedUser, setSelectedUser] = useState(undefined);

  const { showAlert } = useContext(AlertContext);
  const [superAdmin, SetSuperAdmin] = useState(false);

  /*
  const callBack = (data) => {
    console.log(data.map((admin) => new Admin(admin)));
  };

  useEffect(() => {
    const admins = Admin.getAllAdmins(callBack);
    console.log("unsubscribe", admins);
    return () => admins();
  }, []); */
  const getAdmin = async (uid) => {
    const admin = await Admin.getAdmin(uid);
    return admin.data();
  };
  const removeUser = (user) => {
    setDisplayedData((current) => current.filter((u) => u.id !== user.id));
  };

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, async (user) => {
  //     if (user) {
  //       const admin = await getAdmin(user.uid);
  //       const role = await admin.role;
  //       console.log(role);
  //       console.log(ROLE_SUPER_ADMIN);
  //       SetSuperAdmin(role == ROLE_SUPER_ADMIN ? true : false);
  //       console.log(superAdmin);
  //     } else {
  //       // User is signed out
  //       // ...
  //       console.log("user is logged out");
  //     }
  //   });

  //   return () => unsubscribe();
  // }, []);

  const getUsersAndPayments = async () => {
    const data = await User.getAll();
    const payments = await Payment.getAllP();

    setData(data.docs);
    setPayments(payments.docs);
    setDisplayedData(data.docs);
    setLoding(false);
  };

  // const callBackUser = (data) => {
  //   let dataF = data.map(
  //     (userData) =>
  //       new User(userData.id, userData.name, userData.email, userData.avatar)
  //   );
  //   setData(dataF);
  //   setDisplayedData(dataF);
  //   setLoding(false);
  // };

  useEffect(() => {
    // const unsubscribe = User.getAllUsers(callBackUser);
    // return () => unsubscribe();
    getUsersAndPayments();
  }, []);
  const columns = [
    {
      name: (
        <span className="text-lg whitespace-nowrap font-semibold">
          Nom du client
        </span>
      ),

      sortable: false,
      cell: (row) => {
        return (
          <div
            key={row.id}
            className=" gap-2 py-2 flex flex-row items-center w-full"
          >
            <div className="bg-amber-500 rounded-full p-0.5 lg:flex hidden">
              <img
                loading="eager"
                className="w-8 h-8  object-cover rounded-full"
                src={row.data().profile}
                alt={row.data().name}
              />
            </div>
            <div className="flex pl-0 flex-1 flex-col">
              {/* <h1 className="lg:text-lg text-sm font-bold">
                {row.title || "Utilisateur"}
              </h1> */}
              <h1 className="text-base text-gray-400 font-medium">
                {row.data().name}
              </h1>
            </div>
            {/* <h1 className='text-sm text-gray-400'>Email: {row.email}</h1> */}
          </div>
        );
      },
    },
    {
      name: (
        <span className="text-lg whitespace-nowrap font-semibold">
          Date D'inscription
        </span>
      ),
      sortable: true,
      selector: (row) => row.data().created_at,
      cell: (row) => {
        return (
          <div className="flex flex-row justify-center items-center font-medium">
            <h1 className="text-base text-gray-400">
              {moment(row.data().created_at.toDate()).format("DD/MM/YYYY")}
            </h1>
            {/* <h1 className='text-sm text-gray-400'>Email: {row.email}</h1> */}
          </div>
        );
      },
    },
    {
      name: (
        <span className="text-lg whitespace-nowrap font-semibold">
          Total Achat (€)
        </span>
      ),
      sortable: true,
      selector: (row) =>
        payments
          .filter((e) => e.data().userId === row.data().id)
          .reduce((acc, cur) => acc + cur.data().amount, 0),
      width: "150px",

      cell: (row) => {
        return (
          <div className="flex ml-auto flex-row justify-center items-center">
            <h1 className="text-sm ml-auto text-right text-gray-400">
              {payments
                .filter((e) => e.data().userId === row.data().id)
                .reduce((acc, cur) => acc + cur.data().amount, 0)}{" "}
              €
            </h1>
          </div>
        );
      },
    },
    {
      name: <span className="text-lg mx-auto font-bold">Actions</span>,
      sortable: false,

      cell: (row) => {
        return (
          <div className="flex flex-row justify-center gap-3 w-full helper items-center">
            <div className="w-fit group">
              <img
                src={deleteIcon}
                alt="supprimer l'utilisateur"
                className={`text-red-500 w-5 hover:text-red-800 text-2xl cursor-pointer ${
                  superAdmin ? "block" : "hidden"
                }`}
                onClick={() => {
                  setSelectedUser(row);
                  setIsOpen(true);
                }}
              />
            </div>

            <div className="w-fit group helper">
              <img
                src={showIcon}
                alt="afficher plus details"
                className="text-red-500 w-5  hover:text-red-800 cursor-pointer"
                onClick={() => {
                  nav(`/accounting/${row.data().id}`);
                }}
              />
              {/* <span className=" absolute  -top-2 -left-10 bg-gray-800 px-1 text-sm text-gray-100 rounded-md opacity-0 m-4">
                afficher plus details
              </span> */}
            </div>

            <div className="w-fit group helper">
              {!row.data()?.disabled_at && (
                <img
                  src={blockIcon}
                  alt="blocker utilisateur"
                  className="w-5 object-contain cursor-pointer"
                  onClick={() => {
                    setSelectedUser(new User({ id: row.id, ...row.data() }));
                    setOpenBlock(true);
                  }}
                />
              )}

              {!!row.data()?.disabled_at && (
                <img
                  src={enableIcon}
                  alt="blocker utilisateur"
                  className="text-green-500 w-5  object-contain hover:text-green-800  cursor-pointer"
                  onClick={() => {
                    setSelectedUser(new User({ id: row.id, ...row.data() }));
                    setOpenBlock(true);
                  }}
                />
              )}

              {/* <span className="absolute -top-5 bg-gray-800 px-1 text-sm text-gray-100 rounded-md opacity-0 m-4">
                blocker utilisateur
              </span> */}
            </div>
          </div>
        );
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "white",
        backgroundColor: "#1F2937",
      },
    },
  };
  const onChange = async (e) => {
    let catData = data;
    if (e.target.value === "") {
      setDisplayedData(catData);
      return;
    }
    catData = catData.filter((v) => {
      return v.data().name.toLowerCase().includes(e.target.value.toLowerCase());
    });
    console.log(catData);
    setDisplayedData(catData);
  };

  return (
    <div className="w-full h-full px-5 " key="table">
      {open && (
        <SuppDialog
          open={open}
          setIsOpen={setIsOpen}
          setRow={setSelectedUser}
          onConfirme={async () => {
            if (!selectedUser) return;
            try {
              /*await deleteFile(selectedUser.avatar);*/
              console.log("user is ", selectedUser);
              const user = new User({ ...selectedUser.data() });
              await user.delete();
              removeUser(user);
              console.log(user);
              setIsOpen(false);
              setSelectedUser(undefined);

              showAlert("Le user a été supprimée avec succès.");
            } catch (e) {
              console.log(e);
              showAlert(
                "Une erreur s'est produite lors de la suppression du user."
              );
            }
          }}
        />
      )}

      {openBlock && (
        <UserBlockModal
          visible={openBlock}
          onClose={async () => {
            setOpenBlock(false);
            await getUsersAndPayments();
          }}
          selectedUser={selectedUser}
          onConfirm={async () => {
            setOpenBlock(false);
            console.log(selectedUser);

            if (!selectedUser.disabled_at) {
              showAlert("Le user a été bloqué avec succès.");
              selectedUser.disabled_at = new Date();
            } else {
              showAlert("Le user a été activé avec succès.");
              selectedUser.disabled_at = null;
            }
            await selectedUser.save();
            await getUsersAndPayments();
          }}
        />
      )}
      <div className="h-full w-full p-7 ">
        {loding ? (
          <div className="h-40 flex items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="bg-white max-w-full    lg:p-7  p-4 rounded-3xl">
            <div className="flex flex-col">
              <div className="mb-4 flex lg:flex-row flex-col justify-between items-center">
                <h1 className="lg:text-3xl md:text-xl text-lg whitespace-nowrap font-semibold leading-tight text-gray-900 ">
                  Comptabilité
                </h1>
                <div className="flex items-center py-2 lg:w-1/3 w-full">
                  <input
                    onChange={onChange}
                    className="bg-white appearance-none border-2 border-amber-500 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-amber-600"
                    id="inline-searcg"
                    type="text"
                    placeholder="Recherche"
                  />
                </div>
              </div>
              <DataTable
                columns={columns}
                data={displayedData}
                customStyles={customStyles}
                pagination
                paginationPerPage={10}
                noDataComponent="Aucun résultat trouvé"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default UsersTable;
