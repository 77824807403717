import React, { useState, useEffect, useContext } from "react";
import Monument from "../../models/Monument";
import { deleteFile } from "../../api/fileService";
import AlertContext from "../../context/AlertContext/allertContext";
import { baseURL } from "../../api/axiosConfig";
import SuppDialog from "../suppDialog";
import LoadingSpinner from "../loadingSpinner";
import whiteEyeIcon from "../../assets/icons/show-white.svg";
import editIcon from "../../assets/icons/edit.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import MonumentsForm from "./MonumentsForm";

const MonumentsCard = () => {
  const [showFormular, setShowFormular] = useState(false);
  const [isConsulting, setIsConsulting] = useState(false);
  const [selectedMonument, setSelectedMonument] = useState(undefined);
  const [monuments, setMonuments] = useState([]);
  const [displayedMonuments, setDisplayedMonuments] = useState([]);
  const { showAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(true);
  const [open, setIsOpen] = useState(false);

  const callBack = (data) => {
    let monumentsData = data.map((monumentData) => new Monument(monumentData));
    setMonuments(monumentsData);
    setDisplayedMonuments(monumentsData);
    setLoading(false);
  };

  const onChange = (e) => {
    let filteredMonuments = monuments.filter((v) =>
      v.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setDisplayedMonuments(filteredMonuments);
  };

  useEffect(() => {
    const unsubscribe = Monument.getAllMonuments(callBack);
    return () => unsubscribe();
  }, []);

  return (
    <div className="w-full h-full p-7" key="table">
      {open && (
        <SuppDialog
          open={open}
          setIsOpen={setIsOpen}
          setRow={setSelectedMonument}
          text={"Êtes-vous sûr de vouloir supprimer ce monument ?"}
          onConfirme={async () => {
            try {
              // Delete the monument object first
              await selectedMonument.delete();

              // Delete associated images
              if (
                selectedMonument.images &&
                selectedMonument.images.length > 0
              ) {
                await Promise.all(
                  selectedMonument.images.map((imageUrl) =>
                    deleteFile(imageUrl)
                  )
                );
              }

              // Delete the icon
              if (selectedMonument.iconUrl) {
                await deleteFile(selectedMonument.iconUrl);
              }

              // Close dialog and reset state
              setIsOpen(false);
              setSelectedMonument(undefined);

              showAlert("Le monument a été supprimé avec succès.");
            } catch (e) {
              console.error("Error deleting monument:", e);
              showAlert(
                "Une erreur est survenue lors de la suppression du monument.",
                "error"
              );
            }
          }}
        />
      )}
      {showFormular && (
        <MonumentsForm
          setShowFormular={setShowFormular}
          isConsulting={isConsulting}
          setIsconsulting={setIsConsulting}
          data={selectedMonument}
          setSelectedMonument={setSelectedMonument}
        />
      )}
      <div className="bg-white max-w-full p-7 rounded-3xl">
        <div className="flex flex-col">
          <div className="mb-4 flex lg:flex-row flex-col justify-between items-center">
            <div className="mb-4">
              <h1 className="lg:text-3xl md:text-xl text-lg font-bold leading-tight text-gray-900">
                Monuments
              </h1>
            </div>
            <div className="flex lg:w-1/3 w-full py-2">
              <input
                onChange={onChange}
                className="bg-white appearance-none border-2 border-amber-500 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-amber-600"
                id="inline-search"
                type="text"
                placeholder="Recherche"
              />
            </div>
            <div className="flex items-center py-2">
              <button
                onClick={() => {
                  setIsConsulting(false);
                  setShowFormular(true);
                }}
                className="flex items-center rounded-full md:text-base text-sm pl-4 pr-1 py-1 border border-transparent leading-5 font-medium text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:shadow-outline"
              >
                Ajouter un monument
                <div className="bg-white py-2 px-2 text-amber-500 rounded-full text-center items-center text-lg ml-3">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.2111 0C13.8454 0 15.6154 1.84915 15.6154 4.60109V11.0143C15.6154 13.7662 13.8454 15.6154 11.2111 15.6154H4.40426C1.76999 15.6154 0 13.7662 0 11.0143V4.60109C0 1.84915 1.76999 0 4.40426 0H11.2111ZM11.2111 1.08945H4.40426C2.39097 1.08945 1.08945 2.46723 1.08945 4.60109V11.0143C1.08945 13.1482 2.39097 14.5259 4.40426 14.5259H11.2111C13.2251 14.5259 14.5259 13.1482 14.5259 11.0143V4.60109C14.5259 2.46723 13.2251 1.08945 11.2111 1.08945ZM7.80769 4.5955C8.10838 4.5955 8.35242 4.83953 8.35242 5.14022V7.25571L10.4707 7.25585C10.7713 7.25585 11.0154 7.49989 11.0154 7.80057C11.0154 8.10126 10.7713 8.3453 10.4707 8.3453L8.35242 8.34515V10.4618C8.35242 10.7625 8.10838 11.0065 7.80769 11.0065C7.50701 11.0065 7.26297 10.7625 7.26297 10.4618V8.34515L5.14472 8.3453C4.84331 8.3453 4.6 8.10126 4.6 7.80057C4.6 7.49989 4.84331 7.25585 5.14472 7.25585L7.26297 7.25571V5.14022C7.26297 4.83953 7.50701 4.5955 7.80769 4.5955Z"
                      fill="#FEBD2A"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
          {loading && (
            <div className="h-40 w-full flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          <ul className="py-2 grid lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-3">
            {!loading &&
              displayedMonuments.map((monument) => (
                <li
                  key={monument?.id}
                  className="aspect-square rounded-xl overflow-hidden text-center"
                >
                  {console.log({ monument })}
                  <img
                    src={`${monument?.images && monument?.images[0]}`}
                    alt={monument.name}
                    className="w-full h-full object-cover"
                  />
                  <div className="w-11/12 backdrop-blur-lg bg-[#374151] py-2 lg:px-4 px-2 relative bottom-14 border border-gray-600 mx-auto rounded-xl flex items-center justify-between">
                    <span
                      alt={monument.name}
                      className="text-white text-base font-semibold truncate"
                    >
                      {monument.name?.charAt(0).toUpperCase() +
                        monument.name?.slice(1)}
                    </span>
                    <div className="flex gap-2 items-center">
                      <button
                        className="w-4 h-4"
                        onClick={() => {
                          setIsConsulting(false);
                          setSelectedMonument(monument);
                          setShowFormular(true);
                        }}
                      >
                        <img src={editIcon} alt="edit" />
                      </button>
                      <button
                        className="w-4 h-4"
                        onClick={() => {
                          setIsOpen(true);
                          setSelectedMonument(monument);
                        }}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </button>
                      <button
                        className="w-4 h-4"
                        onClick={() => {
                          setSelectedMonument(monument);
                          setIsConsulting(true);
                          setShowFormular(true);
                        }}
                      >
                        <img src={whiteEyeIcon} alt="show" />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            {!loading && !displayedMonuments.length && (
              <p>Aucun résultat trouvé</p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MonumentsCard;
