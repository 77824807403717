import React from "react";
import CouponsTable from "../components/Coupons/CouponsTable";

const CouponsPage = () => {
  return (
    <div className="w-full">
      <CouponsTable />
    </div>
  );
};

export default CouponsPage;
