import React, { useState, useEffect, useContext } from "react";
import { auth } from "../utils/firebase";

import {
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
  updateEmail,
  getAuth,
  sendPasswordResetEmail,
} from "firebase/auth";

// import firebase from "firebase/app"

import { ROLE_SUPER_ADMIN, ROLE_MANAGER } from "../models/Admin";

import ReactSelect from "react-select";

import * as Yup from "yup";
import { useFormik } from "formik";
import AlertContext from "../context/AlertContext/allertContext";
import Admin from "../models/Admin";
import { update } from "lodash";
import { Spinner } from "reactstrap";
import { toogleOverflow } from "../utils/helpers/style_helpers";
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import { useRef } from "react";
import { getFile, uploadFile } from "../api/fileService";
import { baseImageURL } from "../api/axiosConfig";
// import { baseImageURL } from "../api/axiosConfig";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);
const AdminFormular = ({ setShowFormular, data, onSuccess }) => {
  const { showAlert } = useContext(AlertContext);

  const errorMsg = "";

  const error = "";

  const [loading, setLoading] = useState(false);

  const [progress, setProgress] = useState(0);
  const [files, setFiles] = useState([]);
  const imageFilePondRef = useRef(null);
  const [imageProgress, setImageProgress] = useState(0);

  useEffect(() => {
    toogleOverflow();
    console.log(data);
    const filePond = imageFilePondRef.current;
    const fetchFile = async (name) => {
      if (!name) return;
      try {
        const response = await getFile(name);
        const image = response.data;
        const file = new File([image], name, {
          type: "image/jpeg",
        });

        setFiles([file]);
      } catch (error) {
        console.error(error);
      }
    };

    if (data?.id) {
      filePond.removeFiles();
      fetchFile(data.avatar, false);
    }

    return () => {
      toogleOverflow();
    };
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: data ? data.name : "",
      prenom: data ? data.prenom : "",
      role: data ? data.role : ROLE_MANAGER,
      email: data ? data.email : "",
      is_active: data ? data.is_active : false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Veuillez entrer le nom."),
      prenom: Yup.string().required("Veuillez entrer le prenom."),
      // role: Yup.string().required("Veuillez entrer la Role."),
      email: data
        ? Yup.string().nullable()
        : Yup.string().required("Veuillez entrer l'email."),
    }),
    onSubmit: async (values) => {
      try {
        if (data?.id) {
          const res = await fetchSignInMethodsForEmail(auth, values.email);
          console.log("res", res);
          if (res.length > 0 && data.email !== values.email) {
            showAlert("L'email existe déjà", "error");
            validation.setFieldError("email", "Email already exists");
            return;
          } else {
            const newAdmin = new Admin(data);

            if (files.length) {
              let image = await uploadFile(files[0], (progressEvent) => {
                const progressPercentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setImageProgress(progressPercentage);
              });
              newAdmin.avatar = image;
            } else {
              newAdmin.avatar = null;
            }
            setLoading(true);
            newAdmin.prenom = values.prenom;
            newAdmin.name = values.name;
            newAdmin.role = values.role;
            newAdmin.is_active = values.is_active;
            console.log(newAdmin);
            newAdmin.save();
            showAlert("L'utilisateur a été mise à jour avec succès.");

            await onSuccess();
          }
        } else {
          // const userCreated = await createUserWithEmailAndPassword(
          //   auth,
          //   values.email,
          //   "admin1234"
          // );
          setLoading(true);

          const newAdmin = new Admin(values);
          if (files.length) {
            let image = await uploadFile(files[0], (progressEvent) => {
              const progressPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setImageProgress(progressPercentage);
            });
            newAdmin.avatar = image;
          }

          await createUserWithEmailAndPassword(
            auth,
            values.email,
            "admin123"
          ).then(async (userCredential) => {
            const user = userCredential.user;
            const id = user.uid;
            console.log("User created:", user);
            console.log("User created:", id);
            newAdmin.id = id;

            await Admin.create(newAdmin);

            // Reset the form and show success alert
            validation.resetForm();
            sendPasswordResetEmail(auth, values.email);
            showAlert("L'utilisateur a été creé avec succès.");
          });

          // Optionally, you can update the UI or perform any other necessary actions
        }
      } catch (error) {
        console.log("Error creating admin:", error);
        showAlert("Failed to create new admin", "error");
      }

      setLoading(false);
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        setShowFormular(false);
        // return false;
      }}
      action="#"
    >
      <div className="fixed h-full overflow-hidden p-5 z-30 top-0 w-full left-0">
        <div className="flex relative items-center overflow-hidden justify-center  h-full  text-center sm:block  ">
          {loading && (
            <div className="absolute w-full  h-full bg-gray-900 z-30 bg-opacity-40 flex justify-center">
              <Spinner
                children={"Chargement..."}
                className="h-10 w-10 text-main self-center"
              />
            </div>
          )}

          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center bg-white rounded-3xl text-left  
            shadow-xl transform transition-all overflow-y-auto max-h-[calc(100%-4rem)]     sm:align-middle sm:max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white  px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  {data?.id
                    ? "Modification d'un utilisateur"
                    : "​Ajout d'un utilisateur"}
                </h2>

                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-full cursor-pointer"
                  onClick={() => {
                    // setdata(undefined);
                    setShowFormular(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-7 h-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>
              <div className=" grid grid-cols-2 gap-3 ">
                <div className="">
                  <label htmlFor="name" className="font-bold text-sm">
                    Nom <span className="text-red-500">*</span>
                  </label>
                  <input
                    name="name"
                    id="name"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 mt-2 rounded-full"
                    placeholder="Entrer le nom"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.name}
                    </p>
                  ) : null}
                </div>
                <div className="">
                  <label htmlFor="prenom" className="font-bold text-sm">
                    Prénom <span className="text-red-500">*</span>
                  </label>
                  <input
                    name="prenom"
                    id="prenom"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 mt-2 rounded-full"
                    placeholder="Entrer le prénom"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.prenom}
                  />
                  {validation.touched.prenom && validation.errors.prenom ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.prenom}
                    </p>
                  ) : null}
                </div>
                <div className="">
                  <label htmlFor="email" className="font-bold text-sm">
                    Adress E-mail <span className="text-red-500">*</span>
                  </label>
                  <input
                    name="email"
                    id="email"
                    disabled={data?.email ? true : false}
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 mt-2 rounded-full"
                    placeholder="Entrer l'Email"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.email}
                    </p>
                  ) : null}
                </div>
                <div className="">
                  <label htmlFor="is_active" className="font-bold text-sm">
                    Statut <span className="text-red-500">*</span>
                  </label>
                  <ReactSelect
                    name="status"
                    id="is_active"
                    className="w-full  py-2 rounded-full"
                    placeholder="Entrer le status"
                    type="text"
                    onChange={(e) => {
                      validation.setFieldValue("is_active", e?.value, true);
                      validation.setFieldTouched("is_active", true, true);
                    }}
                    onBlur={validation.handleBlur}
                    value={{
                      value: validation.values.is_active,
                      label: validation.values.is_active
                        ? "Active"
                        : "Inactive",
                    }}
                    options={[
                      { value: true, label: "Active" },
                      { value: false, label: "Inactive" },
                    ]}
                  />
                  {validation.touched.is_active &&
                  validation.errors.is_active ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.is_active}
                    </p>
                  ) : null}
                </div>
                <div className="w-full col-span-2">
                  <label className="text-gray-800 block mb-2 font-bold">
                    Avatar <span className="text-red-500"></span>
                  </label>
                  <div>
                    <FilePond
                      ref={imageFilePondRef}
                      files={files}
                      onupdatefiles={setFiles}
                      allowMultiple={false}
                      name="files"
                      className="bg-white p-10"
                      allowFileTypeValidation={true}
                      acceptedFileTypes={[
                        "image/png",
                        "image/jpeg",
                        "image/jpg",
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 text-center">
              <button
                disabled={error ? null : loading ? true : false}
                className="py-3 px-16 bg-orange-600 text-white font-semibold lg:text-lg text-sm rounded-full hover:bg-red-700"
                type="submit"
              >
                {error ? null : loading ? (
                  <h1 className="text-sm me-2"> Loading... </h1>
                ) : data?.id ? (
                  "Mettre à jour"
                ) : (
                  "Sauvegarder"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AdminFormular;
