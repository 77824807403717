import React, { useState, useEffect, useRef, useContext } from "react";
import Coupon from "../../models/Coupon";
import ReactSelect from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import AlertContext from "../../context/AlertContext/allertContext";
import { Progress } from "@material-tailwind/react";
import { toogleOverflow } from "../../utils/helpers/style_helpers";
import { Spinner } from "reactstrap";
import { formatTimeStampToDate } from "../../utils/helpers/formaters";
import { serverTimestamp } from "firebase/firestore"; // Import serverTimestamp from firestore

const CouponForm = ({ setShowFormular, data, setSelectedCoupon, setdata }) => {
  const { showAlert } = useContext(AlertContext);
  const error = "";
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    return () => {
      toogleOverflow();
    };
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      code_name: data ? data.code_name : "",
      discount_value: data ? data.discount_value : "",
      from: data ? formatTimeStampToDate(data.from) : "",
      to: data ? formatTimeStampToDate(data.to) : "",
    },
    validationSchema: Yup.object({
      code_name: Yup.string().required("Veuillez entrer le nom du coupon."),
      discount_value: Yup.number().required(
        "Veuillez entrer le valeur du coupon."
      ),
      from: Yup.date().required(
        "Veuillez entrer la date d'expiration de ce coupon."
      ),
      to: Yup.date().required(
        "Veuillez entrer la date d'expiration de ce coupon."
      ),
    }),
    onSubmit: async (values) => {
      if (loading) return;
      setLoading(true);

      try {
        let couponData = {
          code_name: values.code_name,
          discount_value: values.discount_value,
          from: new Date(values.from).toISOString(),
          to: new Date(values.to).toISOString(),
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        };
        console.log({ data });
        if (!data?.id) {
          // CREATE A NEW Coupon
          await Coupon.create(couponData);
          showAlert("Le Coupon a été créée avec succès.");
        } else {
          // UPDATE A  Coupon
          const couponUpdateData = {
            ...couponData,
            createdAt: data.createdAt, // preserve original createdAt
            updatedAt: serverTimestamp(), // update updatedAt only
          };
          const coupon = new Coupon({ ...data, ...couponUpdateData });
          await coupon.save();
          setSelectedCoupon(undefined);
          showAlert("Le coupon a été mise à jour avec succès.");
        }

        setLoading(false);
        setShowFormular(false);
      } catch (error) {
        console.error("Error in city form submission:", error);
        showAlert("Une erreur s'est produite. Veuillez réessayer.", "error");
        setLoading(false);
      }
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
      action="#"
    >
      <div className="fixed h-full overflow-hidden p-5 z-30 top-0 w-full left-0">
        <div className="flex relative items-center overflow-hidden justify-center  h-full  text-center sm:block  ">
          {loading && (
            <div className="absolute w-full  h-full bg-gray-900 z-30 bg-opacity-40 flex justify-center">
              <Spinner
                children={"Chargement..."}
                className="h-10 w-10 text-main self-center"
              />
            </div>
          )}
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block relative align-center bg-white rounded-3xl text-left  
          shadow-xl transform transition-all overflow-y-auto max-h-[calc(100%-4rem)]     sm:align-middle sm:max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  {!!data?.id
                    ? "Modification d'un coupon"
                    : "Ajout d'un coupon"}
                </h2>
                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-full cursor-pointer"
                  onClick={() => {
                    setdata(undefined);
                    setShowFormular(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-7 h-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <div className="w-full">
                  <label
                    htmlFor="name"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Code coupon <span className="text-red-500">*</span>
                  </label>
                  <input
                    name="code_name"
                    id="code_name"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                    placeholder="Entrer le code du coupon"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.code_name}
                  />
                  {validation.touched.code_name &&
                  validation.errors.code_name ? (
                    <p className="text-red-500 text-xs mt-2">
                      {validation.errors.code_name}
                    </p>
                  ) : null}
                </div>
                <div className="w-full">
                  <label
                    htmlFor="name"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Valeur du coupon <span className="text-red-500">*</span>
                  </label>
                  <input
                    name="discount_value"
                    id="discount_value"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                    placeholder="Entrer la valeur du coupon"
                    type="number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.discount_value}
                  />
                  {validation.touched.discount_value &&
                  validation.errors.discount_value ? (
                    <p className="text-red-500 text-xs mt-2">
                      {validation.errors.discount_value}
                    </p>
                  ) : null}
                </div>
                <div className="w-full">
                  <label
                    htmlFor="name"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Date d'expiration du coupon{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="flex flex-col md:flex-row  w-full justify-center gap-4">
                    <div className="w-full md:w-1/2">
                      <label className="text-sm text-gray-300" htmlFor="from">
                        De
                      </label>
                      <input
                        name="from"
                        id="from"
                        className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                        type="date"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.from}
                      />
                    </div>
                    <div className="w-full md:w-1/2">
                      <label
                        className="text-sm text-gray-300 capitalize pl-2"
                        htmlFor="to"
                      >
                        à
                      </label>
                      <input
                        name="to"
                        id="to"
                        className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                        type="date"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.to}
                      />
                    </div>
                  </div>
                  {/* {validation.touched.expiration_date &&
                  validation.errors.expiration_date ? (
                    <p className="text-red-500 text-xs mt-2">
                      {validation.errors.expiration_date}
                    </p>
                  ) : null} */}
                </div>
              </div>
            </div>
            <div className="w-full">
              <Progress
                className="h-2"
                color="amber"
                value={progress}
                variant="filled"
              />
            </div>
            <div className="bg-gray-50 px-4 py-3 text-center">
              <button
                onClick={() => {
                  setdata(undefined);
                  setShowFormular(false);
                }}
                type="button"
                className="md:py-3 py-2 md:px-8 px-4 bg-gray-500 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-gray-700 mr-2"
              >
                Annuler
              </button>
              <button
                disabled={error ? null : loading ? true : false}
                className="md:py-3 py-2 md:px-10 px-5 bg-amber-400 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-amber-500"
                type="submit"
              >
                {error ? null : loading ? (
                  <h1 className="text-sm me-2"> Loading... </h1>
                ) : // <ComponentLoader /> //loader added
                !!data?.id ? (
                  "Mettre à jour"
                ) : (
                  "Créer"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CouponForm;
