import React from "react";

import AdminsTable from "../components/adminsTable";

const AdminPage = () => {
  return (
    <div className="w-full h-full ">
      <AdminsTable />
    </div>
  );
};

export default AdminPage;
