import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import blockImg from "../assets/icons/interdit.png";
import enableIcon from "../assets/icons/enable.png";

export default function UserBlockModal({
  onClose,
  visible,
  selectedUser,
  onConfirm,
}) {
  return (
    <div className="absolute bg-black/50 opacity-70 w-screen h-screen z-50 top-0 left-0">
      <Dialog
        open={visible}
        size={"sm"}
        //handler={handleOpen}
        className="w-96 rounded-3xl place-self-center"
      >
        <DialogHeader className="h-10">
          <div className="w-full relative flex items-center justify-center pt-3">
            <div
              className="text-gray-400 bg-gray-100 p-2  rounded-full cursor-pointer absolute top-0 right-0"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-7 h-7"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
        </DialogHeader>
        <DialogBody>
          <div className="w-full flex flex-col gap-5 justify-center">
            <img
              src={!selectedUser?.disabled_at ? blockImg : enableIcon}
              alt="trash"
              className="h-20 object-contain"
            />
            <div className="text-lg font-bold text-gray-900 text-center w-10/12 mx-auto -mt-5">
              {selectedUser?.disabled_at
                ? "Vous êtes sûr vous voulez activer cet utilisateur ?"
                : "Vous êtes sûr vous voulez bloquer cet utilisateur ?"}
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <div className="text-center flex gap-4 w-10/12 mx-auto">
            <Button
              variant="text"
              onClick={onClose}
              className=" w-1/2 text-white  bg-amber-400 text-base font-medium lowercase"
            >
              <span>Annuler</span>
            </Button>
            {!selectedUser?.disabled_at ? (
              <Button
                variant="gradient"
                color="green"
                className="w-1/2 bg-orange-600  text-base font-medium lowercase"
                onClick={onConfirm}
              >
                <span>Blocker</span>
              </Button>
            ) : (
              <Button
                variant="gradient"
                color="green"
                className="w-1/2 bg-green-600  text-base font-medium lowercase"
                onClick={onConfirm}
              >
                <span>activer</span>
              </Button>
            )}
          </div>
        </DialogFooter>
      </Dialog>
    </div>
  );
}
