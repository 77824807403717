import React, { useEffect, useState } from "react";
import notificationsIcon from "../assets/icons/header/notifications.svg";
import userIcon from "../assets/icons/header/user.svg";
import { NavLink } from "react-router-dom";

const menuitems = [
  {
    name: "Notifications",
    icon: notificationsIcon,
    link: "/notifications",
  },
  {
    name: "Utilisateurs",
    icon: userIcon,
    link: "/admins",
  },
];

const Header = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (e.target.id !== "menu") {
        setOpen(false);
        // console.log(open, e.target.id)
      }
    });
  }, [open]);

  return (
    <div
      style={{ minWidth: "-webkit-fill-available" }}
      className=" hidden z-10 lg:flex bg-white lg:px-6 px-1 py-2  shadow-md fixed items-center"
    >
      <div className="flex ml-auto items-center md:justify-end justify-between">
        {/*user info */}
        <div className="flex items-center  md:justify-end justify-between">
          {/*<div className="absolute w-2 h-2 rounded-full bg-green-500 top-0 right-0 border-5 border-white"></div>*/}
          <button
            id="menu"
            className="relative cursor-pointer  flex rounded-xl hover:bg-gray-200 flex-row justify-around items-center w-fit px-4 bg-opacity-60 py-3 "
            onClick={() => {
              setOpen(!open);
              console.log(open);
            }}
          >
            <svg
              id="menu"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-3 text-gray-500  object-contain "
            >
              <path
                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                stroke="#374151"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                stroke="#374151"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div
              id="menu"
              className="text-center text-[#374151] xl:text-lg md:text-sm text-xs"
            >
              Parametres
            </div>

            <div
              className={`${
                !open ? "hidden" : "block"
              } absolute w-[110%] top-[105%] flex justify-around flex-col gap-3 py-3 z-50 rounded-xl shadow-2xl shadow-gray-400 bg-white`}
            >
              {menuitems.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    to={item.link}
                    className={({ isActive }) =>
                      `flex flex-row ${
                        isActive && "bg-amber-200 "
                      }  text-center gap-2 justify-between items-center w-[80%] mx-auto rounded-lg px-4 py-2 h-10 hover:bg-amber-100 cursor-pointer `
                    }
                  >
                    <img src={item.icon} alt={item.name} className="w-5 h-5" />
                    <div className="text-center w-full">{item.name}</div>
                  </NavLink>
                );
              })}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
export default Header;
