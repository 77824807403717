import { useEffect, useState } from "react";
import exportIcon from "../../assets/icons/export.svg";
import newVilles from "../../assets/icons/sidebar/location.svg";
import soldIcon from "../../assets/icons/sidebar/category.svg";
// models
import Visit from "../../models/Visit";
import City from "../../models/City";
import Category from "../../models/Category";

import visitIcon from "../../assets/icons/sidebar/visit.svg";
// modules to export csv file ->
import { CSVLink } from "react-csv";
import LoadingSpinner from "../loadingSpinner";
import { Link } from "react-router-dom";

// const data = [
//     {
//         title: 'Total Sales',
//         IntImprovementsInPercentage: 8,
//         value: '$1k',
//         icon: totalSalesIcon,
//         color: 'blue'
//     },
//     {
//         title: 'Total Orders',
//         IntImprovementsInPercentage: 10,
//         value: '200',
//         icon: newOrderIcon,
//         color: 'yellow'
//     },
//     {
//         title: 'Products Sold',
//         IntImprovementsInPercentage: 500,
//         value: '4',
//         icon: soldIcon,
//         color: 'green'
//     },
//     {
//         title: 'New Customers',
//         IntImprovementsInPercentage: 0.3,
//         value: '3',
//         icon: newCustomerIcon,
//         color: 'violet'
//     }
// ]

export default function SalesSummery() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([
    {
      title: "Nombre de villes",
      IntImprovementsInPercentage: 8,
      value: "...",
      icon: newVilles,
      color: "bg-blue-500",
      border: "bg-blue-400 bg-opacity-20 ",
      bg: "bg-blue-300 ",
      hover: " hover:border-blue-500",
      to: "cities",
    },

    {
      title: "Nombre de thèmes",
      IntImprovementsInPercentage: 500,
      value: "...",
      icon: soldIcon,
      bg: "bg-green-300 ",
      border: "bg-green-400 bg-opacity-20 ",
      color: "bg-green-300",
      hover: "hover:border-2 hover:border-green-500",
      to: "themes",
    },
    {
      title: "Nombre de visites",
      IntImprovementsInPercentage: 10,
      value: "...",
      icon: visitIcon,
      bg: "bg-yellow-300 ",
      border: "bg-yellow-400 bg-opacity-20 ",
      color: "bg-yellow-300",
      hover: "hover:border-2 hover:border-yellow-500",
      to:"visits"
    },
  ]);

  const getData = async () => {
    const visits = await Visit.getAllVs();
    const villes = await City.getAllC();
    const categories = await Category.getAllCat();
    // console.log(new Date(users.docs[0].data().created_at).toDateString() );
    // console.log(visits);
    const ville = data[0];
    const category = data[1];
    const visit = data[2];
    category.value = categories.docs.length;

    ville.value = villes.docs.length;

    visit.value = visits.docs.length;

    setData([ville, visit, category]);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getData().then(() => setLoading(false));
  }, []);

  return (
    <div className="w-full mx-auto  flex flex-col gap-5 justify-between items-center pt-4 p-3 lg:p-[3vw] bg-white rounded-3xl shadow-2xl shadow-black/10  mb-5">
      <div className="w-full flex flex-row justify-between">
        <div className="w-fit flex flex-col gap-1">
          <h1 className="font-semibold text-gray-700 text-3xl font-poppins">Resumé</h1>
        </div>
        <div>
          <CSVLink
            data={data.map((item) => {
              return {
                title: item.title,
                value: item.value,
              };
            })}
            filename={"brief-summery.csv"}
            className="rounded-xl border-2 hover:bg-gray-300 flex flex-row justify-around items-center gap-2 border-gray-300 px-4 py-3 transform mr-2 text-gray-800 font-semibold"
          >
            <img className="w-5" src={exportIcon} alt="export icon" />

            <span className="hidden md:block">Telécharger</span>
          </CSVLink>
        </div>
      </div>

      <div className="w-full  grid grid-cols-1 md:grid-cols-3 gap-2 lg:gap-4 py-5 px-3">
        {loading ? (
          <div className="h-40 w-full col-span-3 flex items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          data.map((item, index) => {
            return (
              <Link
                to={item.to}
                key={index}
                className={`w-full p-7 rounded-2xl flex items-center lg:items-start flex-row lg:flex-col gap-2 ${item.bg} border-2 border-transparent ${item.hover} bg-opacity-30 shadow-2xl shadow-transparent`}
              >
                <div
                  className={`w-14 h-14 flex justify-center items-center p-1 ${item.border} rounded-full`}
                >
                  <div
                    className={`w-10 h-10 flex justify-center items-center p-[10px] ${item.bg} rounded-full`}
                  >
                    <img src={item.icon} alt="" />
                  </div>
                </div>
                <h1 className="text-2xl font-semibold">{item.value}</h1>
                <h1 className="text-base  font-medium text-[#425166]">
                  {item.title}
                </h1>
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
}
