import React, { useState, useEffect, useContext } from "react";
import LoadingSpinner from "./loadingSpinner";
import userIcon from "../assets/icons/params/user.svg";
import notificationIcon from "../assets/icons/params/notification.svg";
import activityIcon from "../assets/icons/params/activity.svg";
import bagIcon from "../assets/icons/params/bag.svg";
import Admin from "../models/Admin";
import { useParams } from "react-router-dom";
import AdminFormular from "./adminForm";
import AlertContext from "../context/AlertContext/allertContext";
import { baseImageURL } from "../api/axiosConfig";

const ShowAdminTable = () => {
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState(null);
  const [show, setShow] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const { id } = useParams();
  const getAdmin = async () => {
    await Admin.getAdmin(id).then((data) => {
      console.log(data.data());
      setAdmin(new Admin({ id: data.id, ...data.data() }));
      setLoading(false);
    });
  };
  useEffect(() => {
    getAdmin();
  }, []);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (e.target.id !== "actions") {
        setActionsOpen(false);
      }
    });
    return window.removeEventListener("click", (e) => {});
  }, []);
  // const callBack = (data) => {
  //   console.log(data.data());
  //   setAdmin(new Admin(data.data()));
  //   setLoading(false);
  // };
  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="md:w-11/12 w-full lg:ml-5 ml-2 mt-8 flex md:flex-row flex-col items-start gap-5 pb-8">
            {show && (
              <AdminFormular
                setShowFormular={setShow}
                data={admin}
                setdata={setAdmin}
                onSuccess={async () => {
                  await getAdmin();
                }}
              />
            )}
            {/* user Info */}
            <div className="md:w-1/4 w-full bg-white rounded-2xl py-5 flex flex-col items-center shadow-sm">
              <img
                src={admin?.avatar}
                className="w-28 h-28 rounded-full mb-3 border-2 border-yellow-500 object-cover"
                alt=""
              />
              <h2 className="text-xl font-bold text-[#1f2937] mb-1">
                {admin?.name} {admin?.prenom}
              </h2>
              <p className="text-gray-600 text-base">{admin?.email}</p>
              <p className="text-gray-600 text-sm">{admin?.role}</p>
              <p className="text-gray-600 text-sm">{admin?.is_active}</p>

              {admin?.is_active ? (
                <div className=" text-xs px-2 mt-3  font-semibold text-center bg-green-500 rounded-full text-white">
                  Active
                </div>
              ) : (
                <div className=" text-xs px-2 mt-3  font-semibold text-center bg-red-500 rounded-full text-white">
                  Inactive
                </div>
              )}
            </div>
            {/* Params */}
            <div className="flex-1 bg-white rounded-xl py-5">
              <div className="lg:px-12 flex justify-between items-center md:px-5 px-2">
                <div className="">
                  <h1 className="lg:text-xl text-lg font-semibold text-[#1f2937] mb-1">
                    Informations personnelles
                  </h1>
                  <p className="text-gray-500 lg:text-base text-sm">
                    Informations de base, comme votre nom et votre adresse, que
                    vous utilisez sur la plate-forme.
                  </p>
                </div>
                <div className=" relative flex flex-col  justify-end  items-end">
                  <button
                    id="actions"
                    onClick={() => setActionsOpen(!actionsOpen)}
                    className="px-2 py-1 h-fit rounded-xl bg-transparent text-yellow-600 hover:text-yellow-900 hover:bg-gray-200"
                  >
                    Actions
                  </button>
                  <div
                    id="actions"
                    className={`absolute ${
                      actionsOpen ? "flex " : "hidden"
                    } w-80  right-0 top-10  bg-white flex-col border-gray-200 border-2 shadow-2xl shadow-gray-400 rounded-xl gap-2 justify-center items-center`}
                  >
                    <button
                      id="actions"
                      onClick={() => {
                        setShow(true);
                        // console.log(openBlockModal, "this is test visibility");
                      }}
                      className="cursor-pointer text-green-500 hover:text-green-800 hover:bg-green-100 w-full rounded-xl flex justify-center items-center p-3"
                    >
                      <svg
                        class="w-4 h-4 mr-2 "
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 18"
                      >
                        <path d="M12.687 14.408a3.01 3.01 0 0 1-1.533.821l-3.566.713a3 3 0 0 1-3.53-3.53l.713-3.566a3.01 3.01 0 0 1 .821-1.533L10.905 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V11.1l-3.313 3.308Zm5.53-9.065.546-.546a2.518 2.518 0 0 0 0-3.56 2.576 2.576 0 0 0-3.559 0l-.547.547 3.56 3.56Z" />
                        <path d="M13.243 3.2 7.359 9.081a.5.5 0 0 0-.136.256L6.51 12.9a.5.5 0 0 0 .59.59l3.566-.713a.5.5 0 0 0 .255-.136L16.8 6.757 13.243 3.2Z" />
                      </svg>
                      Modifier
                    </button>
                    <button
                      id="actions"
                      onClick={async () => {
                        await admin
                          .sendResetPasswordEmail()
                          .then(() => {
                            showAlert(
                              "E-mail de réinitialisation du mot de passe envoyé."
                            );
                          })
                          .catch(() => {
                            showAlert(
                              "Erreur lors de l'envoi de l'e-mail de réinitialisation du mot de passe.",
                              "error"
                            );
                          });
                      }}
                      className="cursor-pointer text-yellow-500 hover:text-yellow-800 hover:bg-yellow-100 w-full rounded-xl flex justify-center gap-3 items-center p-3"
                    >
                      <svg
                        class="w-4 h-4 "
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 20"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16 1v5h-5M2 19v-5h5m10-4a8 8 0 0 1-14.947 3.97M1 10a8 8 0 0 1 14.947-3.97"
                        />
                      </svg>
                      Réinitialiser le mot de passe
                    </button>
                  </div>
                </div>
              </div>
              <div className="lg:px-12 md:px-5 px-2 py-2 bg-[#1f2937]/10 my-4">
                <h2 className="lg:text-base text-sm font-semibold text-[#1f2937]">
                  Informations de base
                </h2>
              </div>
              <ul className="lg:px-12 md:px-5 px-2 mb-8">
                <li className="flex justify-between text-gray-500 py-5 border-b-2 border-gray-200 lg:text-base text-sm">
                  <span>Nom et prénom</span>
                  <span>{admin.name + " " + admin.prenom}</span>
                </li>

                <li className="flex justify-between text-gray-500 py-5 border-b-2 border-gray-200 lg:text-base text-sm">
                  <span>Adresse e-mail</span>
                  <span>{admin.email}</span>
                </li>
              </ul>
              {/* <div className="lg:px-12 md:px-5 px-2 py-2 bg-[#1f2937]/10 my-4">
            <h2 className="font-semibold text-[#1f2937] lg:text-base text-sm">
            Préférences
            </h2>
          </div>
          <ul className="lg:px-12 md:px-5 px-2">
            <li className="grid grid-cols-3 justify-between text-gray-500 py-5 border-b-2 border-gray-200 lg:text-base text-sm">
              <span>Langue</span>
              <span>Français</span>
              <span className="text-[#1f2937] place-self-end">
              Modifier la langue
              </span>
              </li>
              <li className="grid grid-cols-3 justify-between text-gray-500 py-5 border-b-2 border-gray-200 lg:text-base text-sm">
              <span>Format de date</span>
              <span>MM/DD/YY</span>
              <span className="text-[#1f2937] place-self-end">Modifier</span>
            </li>
            <li className="grid grid-cols-3 justify-between text-gray-500 py-5 lg:text-base text-sm">
              <span>Fuseau horaire</span>
              <span>BD (GMT +06)</span>
              <span className="text-[#1f2937] place-self-end">Modifier</span>
              </li>
          </ul> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ShowAdminTable;
