import React, { useState, useEffect } from "react";
import PointsTable from "../components/pointsTable";

const PointsPage = () => {
  return (
    <div className="w-full h-full">
      <PointsTable />
    </div>
  );
};

export default PointsPage;
