import React, { useState, useEffect, useContext } from "react";
import CityFormular from "./cityForm";
import DataTable from "react-data-table-component";
import City from "../models/City";
import MapComponent from "./Map";
import { deleteFile } from "../api/fileService";
import AlertContext from "../context/AlertContext/allertContext";
import SuppDialog from "./suppDialog";
import LoadingSpinner from "./loadingSpinner";
// import { GoogleApiWrapper, Map, Marker } from "google-maps-react";

import plusIcon from "../assets/icons/plus.svg";
import editIcon from "../assets/icons/edit.svg";
import deleteIcon from "../assets/icons/delete.svg";

const CityTable = () => {
  const [showFormular, setShowFormular] = useState(false);

  const [selectedCity, setSelectedCity] = useState(undefined);

  const [displayedData, setDisplayedData] = useState([]);

  const [data, setData] = useState([]);

  const [showMap, setShowMap] = useState(false);

  const { showAlert } = useContext(AlertContext);

  const [loding, setLoding] = useState(true);

  const [open, setIsOpen] = useState(false);

  const columns = [
    {
      name: (
        <span className=" text-lg whitespace-nowrap font-semibold">Nom</span>
      ),
      cell: (row) => <span className="capitalize "> {row.name}</span>,
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: (
        <span className=" text-lg whitespace-nowrap font-semibold">
          Date de création
        </span>
      ),
      selector: (row) => row.creationDate,
      sortable: true,
    },

    {
      name: (
        <span className=" text-lg whitespace-nowrap font-semibold mx-auto">
          Actions
        </span>
      ),
      sortable: false,

      cell: (row) => {
        return (
          <div className="flex mx-auto flex-row gap-4">
            <img
              src={editIcon}
              alt="edit"
              className=" cursor-pointer"
              onClick={() => {
                setSelectedCity(row);
                setShowFormular(true);
              }}
            />

            <img
              src={deleteIcon}
              alt="delete"
              className="cursor-pointer"
              onClick={() => {
                setIsOpen(true);
                setSelectedCity(row);
              }}
            />
          </div>
        );
      },
    },
  ];

  const callBack = (data) => {
    data = data.map(
      (cityData) => new City(cityData)
      //    new City(
      //   cityData.id,
      //   cityData.name,
      //   cityData.image,
      //   cityData.creationDate
      // )
    );
    setData(data);
    setDisplayedData(data);
    setLoding(false);
  };


  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "white",
        backgroundColor: "#1F2937",
      },
    },
  };

  useEffect(() => {
    const unsubscribe = City.getAllCities(callBack);
    return () => unsubscribe();
  }, []);

  const onChange = async (e) => {
    let citiesData = data;
    citiesData = citiesData.filter((v) =>
      v.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setDisplayedData(citiesData);
  };

  return (
    <div className="  px-5 h-full" key="table">
      {open && (
        <SuppDialog
          open={open}
          text={"Êtes-vous sûr de vouloir supprimer cette ville ?"}
          setIsOpen={setIsOpen}
          setRow={setSelectedCity}
          onConfirme={async () => {
            try {
              // First, delete the associated image file from Firebase Storage
              if (selectedCity.image) {
                try {
                  await deleteFile(selectedCity.image);
                  await deleteFile(selectedCity.iconUrl);

                } catch (fileError) {
                  // Log the error but continue with city deletion
                  console.warn("Error deleting city image:", fileError);
                }
              }

              // Then, delete the city from the database
              await selectedCity.delete();

              setIsOpen(false);
              setSelectedCity(undefined);

              showAlert("La ville a été supprimée avec succès.");
            } catch (e) {
              console.error("Error deleting city:", e);
              showAlert(
                "Une erreur est survenue lors de la suppression de la ville.",
                "error"
              );
            }
          }}
        />
      )}
      {showMap && (
        <MapComponent
          lat={selectedCity.coordinates[0]}
          lng={selectedCity.coordinates[1]}
          setShowMap={setShowMap}
          setSelectedRow={setSelectedCity}
        />
      )}
      {showFormular && (
        <CityFormular
          setShowFormular={setShowFormular}
          data={selectedCity}
          setSelectedCity={setSelectedCity}
          setdata={setSelectedCity}
          allData={data}
        />
      )}
      <div className="h-full w-full p-7 ">
        <div className="bg-white h-full w-full p-5 flex flex-col rounded-3xl">
          <div className="mb-4 flex lg:flex-row flex-col justify-between items-center">
            <h1 className="lg:text-3xl md:text-xl text-lg font-bold leading-tight text-gray-900">
              Villes
            </h1>

            <div className="flex lg:w-1/3 w-full py-2">
              <input
                onChange={onChange}
                className="bg-white appearance-none border-2 border-amber-500 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-amber-600"
                id="inline-searcg"
                type="text"
                placeholder="Recherche"
              />
            </div>
            <div className="flex items-center py-2">
              <button
                onClick={() => setShowFormular(true)}
                className="flex items-center rounded-full md:text-base text-sm pl-4 pr-1 py-1 border border-transparent leading-5 font-medium  text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:shadow-outline"
              >
                Créer une ville
                <img
                  src={plusIcon}
                  alt="add"
                  className="lg:w-10 lg:h-10 w-5 ml-4"
                />
              </button>
            </div>
          </div>
          <div className=" py-2 sm:px-6 lg:px-8">
            <div className="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b  ">
              {loding ? (
                <div className="h-40 flex items-center justify-center">
                  <LoadingSpinner />
                </div>
              ) : (
                <DataTable
                  columns={columns}
                  data={displayedData}
                  pagination
                  noDataComponent="Aucun résultat trouvé"
                  customStyles={customStyles}
                  loding={true}
                  loadingComponent={<div className="bg-red-500">loading</div>}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CityTable;
