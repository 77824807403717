import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../utils/firebase";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import logofull from "../assets/img/full-logo.svg";
import bg from "../assets/img/login-bg.svg";

const LoginPage = () => {
  const navigate = useNavigate();
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const validation = useFormik({
    //enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        .required("Veuillez entrer votre adresse e-mail."),
      password: Yup.string().required("Veuillez entrer votre mot de passe."),
    }),
    onSubmit: (values) => {
      setError(false);
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredential) => {
          const user = userCredential.user;
          navigate("/");
        })
        .catch((error) => {
          setError(true);
        });
    },
  });

  return (
    <div className="min-h-screen flex">
      <div className="flex flex-col lg:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 bg-white">
        <div
          className="lg:w-1/2 bg-cover w-full h-full md:flex hidden flex-auto items-center justify-center lg:p-10 md:p-5 p-3 overflow-hidden text-white"
          style={{ background: "url(" + bg + ")" }}
        >
          <div className="w-full max-w-md z-10">
            <div className="h-1/6 mb-10 flex flex-col justify-center">
              <img src={logofull} className="w-80 h-80" />
            </div>
            <h2 className="xl:text-3xl lg:text-2xl md:text-xl lg:w-5/6 w-full text-base text-left font-bold leading-tight mb-6">
              Découvrez une nouvelle façon de visiter.
            </h2>
            <p className="xl:text-xl text-left  lg:w-4/6 w-full  font-light lg:text-lg md:text-base text-sm text-gray-200">
              Les lieux incontournables de chaque ville.
            </p>
          </div>
        </div>

        <div className="flex items-center justify-center h-full w-full lg:w-1/2 pt-8 md:pt-0 xl:p-8 lg:p-4 p-2 md:p-10  sm:rounded-lg md:rounded-none relative">
          <div className="md:w-2/3 w-full">
            <div className="text-center">
              <h2 className="mb-16 xl:text-3xl lg:text-xl text-lg font-bold text-orange-600">
                Bienvenue
              </h2>
              <p className="lg:text-xl md:text-base text-sm font-bold text-blue-950">
                Connectez-vous à votre compte.
              </p>
            </div>
            <div className="flex flex-row justify-center items-center space-x-3 mt-5">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                action="#"
                className="w-full"
              >
                <div className="relative">
                  <label
                    htmlFor="email-address"
                    className="ml-3 text-sm font-medium text-gray-700 tracking-wide absolute px-2 bg-white -top-3 -left-1"
                  >
                    Adresse e-mail <span className="text-red-500">*</span>
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoFocus
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    className="w-full text-base px-4 py-3 border rounded-lg border-gray-300 focus:outline-none focus:border-indigo-500"
                    placeholder="Saisissez votre adresse e-mail"
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <p className="text-red-500 text-sm ml-2">
                      {validation.errors.email}
                    </p>
                  ) : null}
                </div>
                <div className="mt-8 content-center relative">
                  <label className="ml-3 sm:text-sm font-medium text-gray-700 tracking-wide absolute px-2 bg-white -top-3 -left-1">
                    Mot de passe <span className="text-red-500">*</span>
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    className=" w-full text-base px-4 py-3 border rounded-lg border-gray-300 focus:outline-none focus:border-indigo-500"
                    placeholder="Saisissez votre mot de passe"
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <p className="text-red-500 text-sm ml-2">
                      {validation.errors.password}
                    </p>
                  ) : null}
                </div>
                <h1
                  className={`mb-5 text-red-500 ${
                    error ? "visible" : "hidden"
                  }`}
                >
                  Nom d'utilisateur ou mot de passe incorrect.
                </h1>
                {/* <div className="flex items-center justify-between">
                                    <div className="flex items-center py-8">
                                        <input id="remember_me" name="remember_me" type="checkbox"
                                            className="h-4 w-4 bg-blue-500 focus:ring-blue-400 border-gray-300 rounded" />
                                        <label for="remember_me" className="ml-2 block text-sm text-gray-900">
                                            Se souvenir de moi
                                        </label>
                                    </div>
                                    <div className="text-sm py-8">
                                        <a href="#" className="text-amber-400 hover:text-amber-500">
                                            Mot de passe oublié
                                        </a>
                                    </div>
                                </div> */}
                <div className="mt-8 flex justify-between ">
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      id="remeber"
                      className="sm:w-4 w-3 sm:h-4 h-3"
                    />
                    <label
                      htmlFor="remeber"
                      className="text-gray-500 sm:text-sm text-xs"
                    >
                      Se souvenir de moi
                    </label>
                  </div>
                  <div>
                    <Link
                      to="/forgot-password"
                      className="text-gray-500 sm:text-sm text-xs"
                    >
                      Mot de passe oublié
                    </Link>
                  </div>
                </div>
                <div className="mt-8 mb-12">
                  <button
                    type="submit"
                    className="w-full flex justify-center bg-gradient-to-r from-amber-400 to-amber-500  hover:bg-gradient-to-l hover:from-amber-400 hover:to-amber-500 p-4 rounded-xl tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500 text-gray-700"
                  >
                    Connexion
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="absolute bottom-5 w-11/12 text-center">
            <p className="text-gray-400 text-sm font-semibold">
              © 2023 Girouette. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
