import { filter } from "lodash";
import {
  deleteOne,
  getAll,
  getDocument,
  getDocuments,
  updateOne,
} from "../utils/database";
import Payment from "./payment";

class User {
  constructor({
    id,
    name,
    email,
    phone,
    avatar,
    notifications,
    birthday,
    deleted_at,
    disabled_at,
    profile,
    created_at,
    tokenFcm,
  }) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.avatar = avatar;
    this.profile = profile;
    this.phone = phone ?? "";
    this.birthday = birthday ?? "";
    this.deleted_at = deleted_at;
    this.disabled_at = disabled_at;
    this.created_at = created_at;
    this.tokenFcm = tokenFcm;
    this.notifications = notifications ?? [];
  }

  // constructor(id, name, email, phone, avatar, notifications, birthdate) {
  //   this.id = id;
  //   this.name = name;
  //   this.email = email;
  //   this.avatar = avatar;
  //   this.phone = phone ?? "";
  //   this.birthdate = birthdate ?? "";
  //   this.listVisits = [];
  //   this.points = [];

  //   this.favouriteVisits = [];
  //   this.notifications = [];
  //   this.history = [];
  //   this.download = [];
  //   this.notifications = notifications ?? [];
  // }

  static collectionName = "users";

  static getAllUsers(callBack) {
    return getAll(this.collectionName, callBack);
  }

  static getAll(querySearch) {
    return getDocuments(this.collectionName, querySearch);
  }

  saveFavouriteVisit(visitId) {
    if (!this.favouriteVisits.includes(visitId)) {
      this.favouriteVisits.push(visitId);
    }
  }

  removeFavouriteVisit(visitId) {
    const index = this.favouriteVisits.indexOf(visitId);
    if (index > -1) {
      this.favouriteVisits.splice(index, 1);
    }
  }

  addNotification(notification) {
    this.notifications.push(notification);
  }

  clearNotifications() {
    this.notifications = [];
  }

  addToHistory(visitId) {
    if (!this.history.includes(visitId)) {
      this.history.push(visitId);
    }
  }

  static async getPayments(id) {
    const payments = await Payment.getAllP();
    // console.log(payments.docs);
    const fileredData = payments.docs.filter((payment) => {
      return payment.data().userId === id;
    });
    // console.log(fileredData)
    return fileredData;
  }

  async delete() {
    await deleteOne(User.collectionName, this.id);
  }

  test() {
    console.log("test");
  }

  static async getUserById(id) {
    return await getDocument(this.collectionName, id);
  }

  async updateUser() {
    console.log("updating");
    if (!User.collectionName || !this.id) {
      console.log("bad");
      return;
    }
    await updateOne(User.collectionName, this.id, {
      name: this.name,
      email: this.email,
      phone: this.phone,
      birthday: this.birthday,
      avatar: this.avatar,
      disabled_at: this.disabled_at,
    });
  }
  async save() {
    console.log({
      id: this.id,
      name: this.name,
      email: this.email,
      disabled_at: this.disabled_at,
    });
    if (!User.collectionName || !this.id) {
      return;
    }
    await updateOne(User.collectionName, this.id, {
      name: this.name,
      email: this.email,
      phone: this.phone,
      birthday: this.birthday,
      notifications: this.notifications,
      disabled_at: this.disabled_at || null,
    });
  }
}
export default User;
