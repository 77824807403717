import React, { useState, useEffect } from "react";
import CityTable from "../components/cityTable";
import City from "../models/City";

const CitiesPage = () => {
  return (
    <div className="flex-1">
      <CityTable />
    </div>
  );
};

export default CitiesPage;
