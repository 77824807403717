import React, { useState, useEffect } from "react";
import UsersTable from "../components/usersTable";

const UsersPage = () => {
  return (
    <div className="w-full">
      <UsersTable />
    </div>
  );
};

export default UsersPage;
