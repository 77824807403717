import React from "react";

import userIcon from "../assets/icons/params/user.svg";
import notificationIcon from "../assets/icons/params/notification.svg";
import activityIcon from "../assets/icons/params/activity.svg";
import bagIcon from "../assets/icons/params/bag.svg";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const ParamsTable = () => {
  const { id } = useParams();

  useEffect(() => {
    console.log("id", id);
  }, []);
  return (
    <>
      <div className="md:w-11/12 w-full lg:ml-5 ml-2 mt-8 flex md:flex-row flex-col items-start gap-5 pb-8">
        {/* user Info */}
        <div className="md:w-1/4 w-full bg-white rounded-2xl py-5 flex flex-col items-center shadow-sm">
          <img
            src="https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=344&q=80"
            className="w-28 h-28 rounded-full mb-3 border-2 border-yellow-500 object-cover" alt=""
          />
          <h2 className="text-xl font-bold text-yellow-400 mb-1">
            Abdelilah Laloui
          </h2>
          <p className="text-gray-600 text-base">Contact@Girouette.com</p>
          <ul className="border-t border-t-gray-200 w-full mt-5 pt-4 px-5 pl-7">
            <li className="font-medium mb-3 flex gap-3 items-center">
              <img src={userIcon} className="w-6 h-6" alt="" />
              Informations personnelles
            </li>
            <li className="font-medium mb-3 flex gap-3 items-center">
              <img src={notificationIcon} className="w-6 h-6" alt="" />
              Notifications
            </li>
            <li className="font-medium mb-3 flex gap-3 items-center">
              <img src={activityIcon} className="w-6 h-6" alt="" />
              Activité du compte
            </li>
            <li className="font-medium mb-3 flex gap-3 items-center">
              <img src={bagIcon} className="w-6 h-6" alt="" />
              Paramètres de sécurité
            </li>
          </ul>
        </div>
        {/* Params */}
        <div className="flex-1 bg-white rounded-xl py-5">
          <div className="lg:px-12 md:px-5 px-2">
            <h1 className="lg:text-xl text-lg font-semibold text-yellow-400 mb-1">
              Informations personnelles
            </h1>
            <p className="text-gray-500 lg:text-base text-sm">
              Informations de base, comme votre nom et votre adresse, que vous
              utilisez sur la plate-forme.
            </p>
          </div>
          <div className="lg:px-12 md:px-5 px-2 py-2 bg-yellow-400/10 my-4">
            <h2 className="lg:text-base text-sm font-semibold text-yellow-400">
              Informations de base
            </h2>{" "}
          </div>
          <ul className="lg:px-12 md:px-5 px-2 mb-8">
            <li className="flex justify-between text-gray-500 py-5 border-b-2 border-gray-200 lg:text-base text-sm">
              <span>Nom et prénom</span>
              <span>Alex Jonsion</span>
            </li>
            <li className="flex justify-between text-gray-500 py-5 border-b-2 border-gray-200 lg:text-base text-sm">
              <span>Nom d’affichage</span>
              <span>Alex Jonsion</span>
            </li>
            <li className="flex justify-between text-gray-500 py-5 border-b-2 border-gray-200 lg:text-base text-sm">
              <span>Adresse e-mail</span>
              <span>contact@girouette.com</span>
            </li>
            <li className="flex justify-between text-gray-500 py-5 border-b-2 border-gray-200 lg:text-base text-sm">
              <span>Numéro de téléphone</span>
              <span>+00 123 456 789</span>
            </li>
            <li className="flex justify-between text-gray-500 py-5 border-b-2 border-gray-200 lg:text-base text-sm">
              <span>Date de naissance</span>
              <span>01 Jan 2022</span>
            </li>
            <li className="flex justify-between text-gray-500 py-5 ">
              <span>Addresse postale</span>
              <span>Paris, France</span>
            </li>
          </ul>
          <div className="lg:px-12 md:px-5 px-2 py-2 bg-yellow-400/10 my-4">
            <h2 className="font-semibold text-yellow-400 lg:text-base text-sm">
              Préférences
            </h2>
          </div>
          <ul className="lg:px-12 md:px-5 px-2">
            <li className="grid grid-cols-3 justify-between text-gray-500 py-5 border-b-2 border-gray-200 lg:text-base text-sm">
              <span>Langue</span>
              <span>Français</span>
              <span className="text-yellow-400 place-self-end">
                Modifier la langue
              </span>
            </li>
            <li className="grid grid-cols-3 justify-between text-gray-500 py-5 border-b-2 border-gray-200 lg:text-base text-sm">
              <span>Format de date</span>
              <span>MM/DD/YY</span>
              <span className="text-yellow-400 place-self-end">Modifier</span>
            </li>
            <li className="grid grid-cols-3 justify-between text-gray-500 py-5 lg:text-base text-sm">
              <span>Fuseau horaire</span>
              <span>BD (GMT +06)</span>
              <span className="text-yellow-400 place-self-end">Modifier</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ParamsTable;
