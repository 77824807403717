import React, { useState, useEffect, useRef, useContext } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Point from "../models/Point";
import {
  deleteFile,
  getFile,
  updateFile,
  uploadFile,
} from "../api/fileService";
import ReactSelect from "react-select";
import Visit from "../models/Visit";
import MapComponent from "./Map";
import AlertContext from "../context/AlertContext/allertContext";
import { Progress } from "@material-tailwind/react";
import { where } from "firebase/firestore";
import { Spinner } from "@material-tailwind/react";
import { customStyles, toogleOverflow } from "../utils/helpers/style_helpers";
import { getDriveDuration } from "../utils/helpers/video_helpers";
import { RiPencilLine } from "react-icons/ri";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const PointForm = ({ setShowFormular, data, setSelectedPoint, setData }) => {
  const { showAlert } = useContext(AlertContext);
  const [currentStep, setCurrentStep] = useState(1);
  const error = "";
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedVisit, setSelectedVisit] = useState(undefined);
  const [visits, setVisits] = useState();
  const [showVisitError, setShowVisitError] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [link, setLink] = useState(null);
  const [showVideoRequiredError, setShowVideoRequiredError] = useState(false);
  const pointImageRef = useRef(null);
  const pointVideoRef = useRef(null);
  const [imageProgress, setImageProgress] = useState(0);
  const [videoProgress, setVideoProgress] = useState(0);
  const [orderMaxValue, setOrderMaxValue] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [coordinates, setCoordinates] = useState(
    data?.coordinates || { lat: 48.864716, lng: 2.349014 }
  );

  const onChange = async (val) => {
    const data = await Point.getAllPt([where("FFidVisit", "==", val.value)]);
    let dataF = [];
    data.forEach((doc) => {
      const id = doc.id;
      dataF.push(new Point({ id, ...doc.data() }));
    });
    dataF.sort(function (a, b) {
      return a.order - b.order;
    });
    setOrderMaxValue(
      dataF[dataF.length - 1] ? dataF[dataF.length - 1].order + 1 : 0
    );
    setSelectedVisit(val);
  };

  const isValidGoogleDriveLink = (link) => {
    const googleDriveRegex =
      /^https:\/\/drive\.google\.com\/(file\/d\/[^/?#]+|open\?id=[^/?#]+)(\/.*)?$/;
    return googleDriveRegex.test(link);
  };

  // Function to extract duration for locally uploaded videos
  const getPhysicalVideoDuration = (videoFile) => {
    return new Promise((resolve, reject) => {
      if (videoFile instanceof File) {
        const videoElement = document.createElement("video");
        const videoURL = URL.createObjectURL(videoFile);

        videoElement.src = videoURL;

        videoElement.onloadedmetadata = () => {
          const duration = videoElement.duration;
          URL.revokeObjectURL(videoURL);
          resolve(duration);
        };

        videoElement.onerror = () => {
          reject(new Error("Failed to load video file"));
        };
      } else {
        reject(new Error("Invalid video file"));
      }
    });
  };

  // Function to convert duration to minutes and seconds
  const convertDurationToMinutes = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // point-form
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || "",
      description: data?.description || "",
      address: data?.address || "",
      link: data?.video?.includes("drive") ? data?.video : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Veuillez entrer le nom du point."),
      description: Yup.string().required(
        "Veuillez entrer la description du point."
      ),
      address: Yup.string().required("Veuillez entrer l'adresse du point."),
      link: Yup.string()
        .matches(
          /^https:\/\/drive\.google\.com\/(file\/d\/[^/?#]+|open\?id=[^/?#]+)(\/.*)?$/,
          "Uniquement les liens google drive"
        )
        .nullable(),
    }),
    validate: (values) => {
      const errors = {};
      if (!data && !file) {
        errors.image = "Une image est requise pour créer un nouveau point.";
      }
      if (!data && !video && !link) {
        errors.video = "Une video est requise pour créer un nouveau point.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      if (loading) return;
      if (!file && (!video || !link)) {
        console.log("first");
        return;
      }
      setLoading(true);

      try {
        if (!selectedVisit) {
          showAlert("Veuillez sélectionner une visite.", "error");
          return;
        }

        showAlert("Préparation des données du point...", "info");
        setProgress(10);

        const pointData = {
          name: values.name.toLowerCase(),
          idVisit: selectedVisit.value,
          coordinates: coordinates,
          description: values.description,
          address: values.address,
          image: data?.image,
          video: data?.video,
        };

        showAlert("Gestion des fichiers...", "info");

        // Handle image file
        if (file && file !== data?.image) {
          try {
            let imageUrl;
            if (data) {
              // Updating existing point
              imageUrl = await updateFile(
                file,
                data.image,
                "points",
                (progressEvent) => {
                  const progressPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(10 + progressPercentage * 0.4);
                }
              );
            } else {
              // Creating new point
              imageUrl = await uploadFile(file, "points", (progressEvent) => {
                const progressPercentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(10 + progressPercentage * 0.4);
              });
            }
            if (imageUrl) {
              pointData.image = imageUrl;
            }
          } catch (e) {
            console.log(e);
            showAlert(`Erreur lors du traitement de l'image`, "error");
            setLoading(false);
            return;
          }
        }

        // Handle video file or link
        if (
          (video && video !== data?.video) ||
          (values.link && values.link !== data?.video)
        ) {
          try {
            let videoUrl;
            let duration = 0;
            console.log({ video });
            if (video) {
              // Check if the video is a string (i.e., a URL)
              if (typeof video === "string" && video.startsWith("http")) {
                console.log("The video is a link:", video);
                // Handle the case where the video is a link
                videoUrl = video; // Assuming you want to store the link directly
                // Optionally handle link-based video metadata here
              } else if (video instanceof File || video instanceof Blob) {
                console.log("The video is a file:", video);

                // Case: Video is a file that needs to be uploaded/updated
                if (data) {
                  console.log("here", video, data);
                  // Update existing video
                  videoUrl = await updateFile(
                    video,
                    data.video,
                    "points",
                    (progressEvent) => {
                      const progressPercentage = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      );
                      setProgress(60 + progressPercentage * 0.3);
                    }
                  );
                } else {
                  console.log("first");
                  // Upload new video
                  videoUrl = await uploadFile(
                    video,
                    "points",
                    (progressEvent) => {
                      const progressPercentage = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      );
                      setProgress(60 + progressPercentage * 0.3);
                    }
                  );
                }

                // Extract and convert video duration (for files)
                const physicalDuration = await getPhysicalVideoDuration(video);
                duration = convertDurationToMinutes(physicalDuration);
                console.log("Physical video duration:", duration);
              } else {
                console.error("Unsupported video format");
              }
            }

            // Case 2: Google Drive link provided by the user
            else if (values.link) {
              if (!isValidGoogleDriveLink(values.link)) {
                throw new Error("Invalid Google Drive link");
              }

              videoUrl = values.link;
              console.log({ videoUrl });
              duration = await getDriveDuration(values.link);
              console.log("Google Drive video duration:", duration);
            }

            // Save the video URL and duration in the point data
            if (videoUrl) {
              pointData.video = videoUrl;
              pointData.duration = duration;
            }
          } catch (e) {
            console.log(e);
            showAlert(`Erreur lors du traitement de la vidéo`, "error");
            setLoading(false);
            return;
          }
        }

        setProgress(85);
        showAlert(
          data ? "Mise à jour du point..." : "Création du point...",
          "info"
        );

        if (data) {
          await Point.update(data.id, pointData);
        } else {
          await Point.create(pointData);
        }

        setProgress(100);
        showAlert(
          data ? "Point mis à jour avec succès." : "Point créé avec succès.",
          "success"
        );
        setShowFormular(false);
        setSelectedPoint(undefined);

        // Update visit distance logic can be re-enabled if needed
        // await Visit.getOne(selectedVisit.value).then(async (doc) => {
        //   const visitData = doc.data();
        //   const visit = new Visit({
        //     id: doc.id,
        //     ...visitData,
        //   });
        //   await Point.getAllPt([where("idVisit", "==", selectedVisit.value)]);
        // });
      } catch (error) {
        console.error("Erreur lors de la soumission du formulaire:", error);
        showAlert("Une erreur s'est produite. Veuillez réessayer.", "info");
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    toogleOverflow();
    const loadVisits = async () => {
      const querySnapshot = await Visit.getAllVs();
      let myData = [];
      querySnapshot.forEach((doc) => {
        myData.push({ value: doc.id, label: doc.data().name });
      });
      setVisits(myData);
      if (data) {
        console.log({ data });
        let d = myData.filter((e) => e.value === data.idVisit);
        //setSelectedVisit(d);
        if (d.length > 0) onChange(d[0]);
      }
    };
    loadVisits();

    if (data?.image) {
      fetchImageFromFirebase(data.image);
      setFile(data.image);
    }
    if (data?.video) {
      fetchVideoFromFirebase(data.video);
      setVideo(data?.video);
    }

    return () => {
      toogleOverflow();
    };
  }, []);

  const handleMapClick = (data) => {
    setCoordinates({
      lng: data.latLng.lng(),
      lat: data.latLng.lat(),
    });
  };

  console.log({ validation });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type.startsWith("video/")) {
        setVideo(selectedFile);
        setVideoPreview(URL.createObjectURL(selectedFile));
        setShowVideoRequiredError(false);
      } else {
        setFile(selectedFile);
        setFilePreview(URL.createObjectURL(selectedFile));
      }
    }
  };

  const fetchImageFromFirebase = async (imageUrl) => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, imageUrl);
      const url = await getDownloadURL(imageRef);
      validation.setFieldValue("image", url);
      setFilePreview(url);
    } catch (error) {
      console.error("Error fetching image from Firebase:", error);
      if (error.code === "storage/unauthorized") {
        showAlert(
          "Permission denied. Please check your Firebase permissions.",
          "error"
        );
      } else {
        showAlert(`Error loading image: ${error.message}`, "error");
      }
    }
  };

  const fetchVideoFromFirebase = async (videoUrl) => {
    try {
      if (videoUrl.includes("firebase")) {
        const storage = getStorage();
        const videoRef = ref(storage, videoUrl);
        const url = await getDownloadURL(videoRef);
        setVideoPreview(url);
      } else {
        return;
      }
    } catch (error) {
      console.error("Error fetching video from Firebase:", error);
      showAlert(`Error loading video: ${error.message}`, "error");
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (currentStep === 1) {
          setCurrentStep(2);
        } else {
          validation.handleSubmit();
        }
        return false;
      }}
      action="#"
    >
      <div className="fixed h-full overflow-hidden p-5 z-30 top-0 w-full left-0">
        {showMap && (
          <MapComponent
            lat={coordinates?.lat}
            lng={coordinates?.lng} // Make sure it's lng here
            setLat={(newLat) =>
              setCoordinates((prev) => ({ ...prev, lat: newLat }))
            }
            setLng={(newLng) =>
              setCoordinates((prev) => ({ ...prev, lng: newLng }))
            }
            setShowMap={setShowMap}
            onClick={handleMapClick}
          />
        )}
        <div className="flex relative items-center overflow-hidden justify-center  h-full  text-center sm:block  ">
          {(showLoader || loading) && (
            <div className="absolute w-full  h-full bg-gray-900 z-30 bg-opacity-40 flex justify-center">
              <Spinner
                children={"Chargement..."}
                className="h-10 w-10 text-main self-center"
              />
            </div>
          )}
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center bg-white rounded-3xl text-left  
          shadow-xl transform transition-all overflow-y-auto max-h-[calc(100%-4rem)]     sm:align-middle sm:max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white  px-6 pt-5 ">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  {data !== undefined
                    ? "Modification d'une point"
                    : "​Ajout d'une point"}
                </h2>
                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-full cursor-pointer"
                  onClick={() => {
                    setShowFormular(false);
                    setData(undefined);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-7 h-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>

              {currentStep === 1 ? (
                <>
                  <div className="mb-3 flex lg:flex-row flex-col gap-3">
                    <div className="w-full">
                      <label
                        htmlFor="name"
                        className="text-gray-800 block mb-2 font-bold"
                      >
                        Nom <span className="text-red-500">*</span>
                      </label>
                      <input
                        name="name"
                        id="name"
                        className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                        placeholder="Entrer le nom"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <p className="text-red-500 text-xs">
                          {validation.errors.name}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className=" w-full mb-3">
                    <label className="text-gray-800 block mb-2 font-bold">
                      Visite <span className="text-red-500">*</span>
                    </label>
                    <ReactSelect
                      value={selectedVisit}
                      isMulti={false}
                      options={visits}
                      onChange={onChange}
                      menuShouldScrollIntoView={true}
                      maxMenuHeight={150}
                      styles={customStyles}
                    />
                    {showVisitError ? (
                      <p className="text-red-500 text-xs">
                        Aucune visite sélectionnée. Veuillez choisir une visite
                        avant de continuer.
                      </p>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="description"
                      className="text-gray-800 block mb-2 font-bold"
                    >
                      Description <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      name="description"
                      className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                      placeholder="Entrer la description"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.description || ""}
                    />
                    {validation.touched.description &&
                    validation.errors.description ? (
                      <p className="text-red-500 text-xs">
                        {validation.errors.description}
                      </p>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="address"
                      className="text-gray-800 block mb-2 font-bold"
                    >
                      Adresse <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      name="address"
                      className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                      placeholder="Entrer l'addresse"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.address}
                    />
                    {validation.touched.address && validation.errors.address ? (
                      <p className="text-red-500 text-xs">
                        {validation.errors.address}
                      </p>
                    ) : null}
                  </div>

                  <div className="flex lg:flex-row flex-col mb-3">
                    <div className="flex-1 lg:mr-2 lg:mb-0 mb-3">
                      <label
                        htmlFor="longtitude"
                        className="text-gray-800 block mb-2 font-bold"
                      >
                        Latitude <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full  border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                        placeholder="Latitude"
                        type="number"
                        value={coordinates.lat}
                        onChange={(e) =>
                          setCoordinates({
                            ...coordinates,
                            lat: parseFloat(e.target.value),
                          })
                        }
                      />
                      {validation.touched.latitude &&
                      validation.errors.latitude ? (
                        <p className="text-red-500 text-xs">
                          {validation.errors.latitude}
                        </p>
                      ) : null}
                    </div>

                    <div className="flex-1 lg:ml-2 lg:mb-0 mb-3">
                      <label
                        htmlFor="longtitude"
                        className="text-gray-800 block mb-2 font-bold"
                      >
                        Longitude <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full  border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full"
                        placeholder="Longitude"
                        type="number"
                        value={coordinates.lng}
                        onChange={(e) =>
                          setCoordinates({
                            ...coordinates,
                            lng: parseFloat(e.target.value),
                          })
                        }
                      />
                      {validation.touched.longtitude &&
                      validation.errors.longtitude ? (
                        <p className="text-red-500 text-xs">
                          {validation.errors.longtitude}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <h1
                    className="mb-3 bg-gray-100 p-2 text-center hover:bg-gray-200 rounded-xl w-full cursor-pointer"
                    onClick={() => setShowMap(true)}
                  >
                    selectionner la position
                  </h1>
                </>
              ) : (
                <>
                  {/* IMAGE UPLOAD - UPDATE */}
                  <div className="w-full flex flex-col gap-2">
                    <label className="text-gray-800 block font-bold">
                      Image <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      {(data?.image || filePreview) && (
                        <label
                          htmlFor="image"
                          className="cursor-pointer absolute bg-orange-500 rounded-full p-1.5 -right-2 -top-2 "
                        >
                          <RiPencilLine className="text-white" size={20} />
                        </label>
                      )}
                      <input
                        ref={pointImageRef}
                        type="file"
                        name="image"
                        id="image"
                        accept="image/*"
                        onChange={handleFileChange}
                        className={`w-full border-2 text-gray-500 ${
                          data?.image || filePreview ? "hidden" : "inline"
                        } outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full appearance-none ${
                          validation.errors.image
                            ? "!border-red-400 text-red-500"
                            : ""
                        }`}
                      />
                      {filePreview && (
                        <img
                          src={filePreview}
                          alt="Visit Preview"
                          className="max-w-full h-auto rounded-xl"
                        />
                      )}
                    </div>

                    <p
                      className={`mt-2 text-xs text-red-600 ${
                        validation.errors.image ? "visible" : "invisible"
                      }`}
                    >
                      {validation.errors.image || "test"}
                    </p>
                  </div>
                  {/* VIDEO UPLOAD - UPDATE */}
                  <div className="w-full flex flex-col gap-2 mt-2.5">
                    <label className="text-gray-800 block font-bold">
                      Video <span className="text-red-500">*</span>
                    </label>
                    <div className="flex flex-col gap-1">
                      <div className="relative w-full">
                        {data?.video.includes("firebase") && videoPreview && (
                          <label
                            htmlFor="video"
                            className="cursor-pointer absolute z-50 bg-orange-500 rounded-full p-1.5 -right-2 -top-2 "
                          >
                            <RiPencilLine className="text-white" size={20} />
                          </label>
                        )}
                        <input
                          ref={pointVideoRef}
                          type="file"
                          name="video"
                          id="video"
                          accept="video/mp4,video/x-m4v,video/*"
                          onChange={handleFileChange}
                          className={`w-full border-2 text-gray-500 ${
                            data?.video || videoPreview ? "hidden" : "inline"
                          } outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-full appearance-none ${
                            validation.errors.image
                              ? "!border-red-400 text-red-500"
                              : ""
                          }`}
                        />
                        {videoPreview && (
                          <div className="">
                            <video
                              src={videoPreview}
                              controls
                              className="max-w-full h-auto rounded-lg shadow-lg"
                            >
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        )}
                      </div>
                      {!data && (
                        <span className="w-full text-center block text-gray-400">
                          -ou-
                        </span>
                      )}
                      {!data?.video.includes("firebase") && (
                        <>
                          <input
                            name="link"
                            id="link"
                            className={`w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 px-4  rounded-full ${
                              validation.errors.video
                                ? "!border-red-400 placeholder:text-red-500"
                                : ""
                            }`}
                            placeholder="Entrer le lien google drive"
                            type="text"
                            onChange={(e) => {
                              console.log(e);
                              setLink(e.target.value);
                              validation.handleChange(e);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.link || ""}
                          />
                        </>
                      )}
                    </div>
                    <p
                      className={`mt-2 text-xs text-red-600 ${
                        validation.errors.video ? "visible" : "invisible"
                      }`}
                    >
                      {validation.errors.video || "test"}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="w-full">
              <Progress
                className="h-2"
                color="amber"
                value={
                  video
                    ? imageProgress * 0.2 + videoProgress * 0.8
                    : imageProgress
                }
                variant="filled"
              />
            </div>
            <div className="bg-gray-50 px-4 py-3 text-center">
              {currentStep === 1 ? (
                <button
                  type="submit"
                  className="md:py-3 py-2 md:px-10 px-5 bg-amber-400 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-amber-500"
                >
                  Suivant
                </button>
              ) : (
                <>
                  <div
                    onClick={() => setCurrentStep(1)}
                    className="md:py-3 py-2 md:px-8 cursor-pointer inline px-4 bg-gray-500 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-gray-700 mr-2"
                  >
                    Précédent
                  </div>
                  {console.log(currentStep)}

                  <button
                    color="success"
                    disabled={error ? null : loading ? true : false}
                    className="md:py-3 py-2 md:px-10 px-5 bg-amber-400 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-amber-500"
                    type="submit"
                  >
                    {error ? null : loading ? (
                      <h1 className="text-sm me-2"> Loading... </h1>
                    ) : data !== undefined ? (
                      "Mettre à jour"
                    ) : (
                      "Créer"
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PointForm;
