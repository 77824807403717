import { MUSEUM_CATEGORY_ID } from "../api/axiosConfig";
import {
  createOne,
  deleteOne,
  getAll,
  getDocuments,
  updateOne,
} from "../utils/database";
import { formatDate } from "../utils/date";

class Category {
  static collectionName = "categories";

  constructor(id, name, image, creationDate) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.creationDate = creationDate;
  }

  static async create(name, image) {
    try {
      const data = {
        name: name.toLowerCase(),
        image,
        creationDate: formatDate(new Date()),
      };
      const docRef = await createOne(this.collectionName, data);
      const id = docRef.id;
      await updateOne(this.collectionName, id, {id})
      return { ...data, id };
    } catch (e) {
      throw e;
    }
  }

  static getAllCategories(callBack) {
    return getAll(this.collectionName, callBack);
  }
  static getAllCat(query = "") {
    return getDocuments(this.collectionName, query);
  }

  // static async getById(id) {
  //     const categoryRef = await db.collection("categories").doc(id).get();

  //     if (categoryRef.exists) {
  //         const categoryData = categoryRef.data();
  //         return new Category(id, categoryData.name);
  //     } else {
  //         throw new Error(`Category with ID ${id} does not exist`);
  //     }
  // }

  async save() {
    await updateOne(Category.collectionName, this.id, {
      name: this.name?.toLowerCase(),
      image: this.image,
    });
  }

  async delete() {
    if (this.id && this.id !== MUSEUM_CATEGORY_ID)
      await deleteOne(Category.collectionName, this.id);
  }
}

export default Category;
