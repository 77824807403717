import React, { useState } from "react";
import NotificationForm from "../components/notificationForm";
import NotificationTable from "../components/notificationTable";
import { FiPlusSquare } from "react-icons/fi";
import User from "../models/User";

const NotificationPage = () => {
  return (
    <div className="w-full">
      <NotificationTable />
    </div>
  );
};

export default NotificationPage;
