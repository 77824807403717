import React from "react";

import AbonnmentTable from "../components/abonnmentTable";

const abonnmentPage = () => {
  return (
    <div className="w-full h-full ">
      <AbonnmentTable />
    </div>
  );
};

export default abonnmentPage;
