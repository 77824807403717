export const toogleOverflow = () => {
  document.body.classList.toggle("overflow-y-hidden");
};

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderWidth: "2px", // border-2
    borderColor: state.isFocused ? "#a0aec0" : "#e2e8f0", // border-gray-200, can change on focus
    backgroundColor: "#f9fafb", // bg-gray-50
    color: "#6b7280", // text-gray-500
    paddingLeft: "5px", // pl-4
    borderRadius: "9999px", // rounded-full
    boxShadow: "none", // outline-none, removes default focus outline
    "&:hover": {
      borderColor: "#a0aec0", // Optional: border change on hover
    },
    textTransform: "capitalize",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "12px", // Optional: rounded corners for the dropdown menu
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#6b7280", // text-gray-500 for the selected value
  }),
};
