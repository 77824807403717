import React, { useState, useEffect, useContext } from "react";
import QRCode from "qrcode.react";
import { saveAs } from "file-saver";
import CouponsForm from "./CouponsForm";
import DataTable from "react-data-table-component";
import Coupon from "../../models/Coupon";
import { deleteFile } from "../../api/fileService";
import AlertContext from "../../context/AlertContext/allertContext";
import SuppDialog from "../suppDialog";
import LoadingSpinner from "../loadingSpinner";
import eyeIcon from "../../assets/icons/show.svg";
import editIcon from "../../assets/icons/edit.svg";
import qrcodeIcon from "../../assets/icons/qrcode.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import { convertSecondsToHMS, formatDateToString } from "../../utils/helpers/formaters";
import { MUSEUM_CATEGORY_ID } from "../../api/axiosConfig";
import ShowCouponsForm from "./ShowCouponsForm";
import { ArrowRightIcon } from "@mui/x-date-pickers";

const CouponsTable = () => {
  const [showFormular, setShowFormular] = useState(false);
  const [showCoupon, setShowCoupon] = useState(false);

  const [selectedCoupon, setSelectedCoupon] = useState(undefined);

  const [displayedData, setDisplayedData] = useState([]);
  const [groupedPoints, setGroupedPoints] = useState([]);

  const [data, setData] = useState([]);

  const { showAlert } = useContext(AlertContext);

  const [loding, setLoding] = useState(true);

  const [open, setIsOpen] = useState(false);

  // const qrCodeCanvasRef = useRef(null);
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  };
  const handleDownload = (id) => {
    // Get the QR code data URL from the canvas
    const canvas = document.getElementById(id);

    // Get the QR code data URL from the canvas
    const dataUrl = canvas.toDataURL("image/png");

    const blob = dataURItoBlob(dataUrl);
    console.log(blob);
    // Use file-saver library to trigger the download
    saveAs(blob, `${id}.png`);
  };

  // const visitHasPayments = async (id) => {
  //   const payments = await Payment.getAllP([where("visitsId", "==", id)]);
  //   return !!payments.docs.length;
  // };

  const columns = [
    {
      name: (
        <span className="text-lg whitespace-nowrap font-semibold">
          Nom du coupon
        </span>
      ),
      minWidth: "150px",

      selector: (row) => row.code_name,
      cell: (row) => <span className="capitalize "> {row.code_name}</span>,
      sortable: true,
    },

    {
      name: (
        <span className="text-lg whitespace-nowrap font-semibold">
          Valeur du coupon
        </span>
      ),

      selector: (row) => row.discount_value,
      cell: (row) => (
        <span className="text-center "> {row.discount_value}</span>
      ),

      sortable: true,
    },
    {
      name: (
        <span className="text-lg whitespace-nowrap font-semibold">
          Date d'expiration
        </span>
      ),

      selector: (row) => row.expiration_date,
      cell: (row) => (
        <span className="text-center font-semibold ">{` ${formatDateToString(row.from)}  ->  ${formatDateToString(row.to)}`}</span>
      ),

      sortable: true,
    },

    {
      name: (
        <span className="text-lg whitespace-nowrap font-semibold mx-auto">
          Actions
        </span>
      ),
      sortable: false,
      maxWidth: "150px",
      cell: (row) => {
        const length = payments[row?.id]?.length ?? 0;

        return (
          <div className="flex flex-row  items-start ml-auto mr-6   gap-3">
            {row?.categories?.includes(MUSEUM_CATEGORY_ID) && (
              <>
                <img
                  src={qrcodeIcon}
                  alt="show"
                  className="text-gray-500 h-4 hover:text-blue-500 text-2xl   cursor-pointer"
                  onClick={() => {
                    handleDownload(row?.id);
                  }}
                />

                <QRCode
                  className="fixed  -top-[99999px]  "
                  onClick={() => {
                    handleDownload(row?.id);
                  }}
                  value={row.id}
                  size={5000}
                  id={row.id}
                />
              </>
            )}
            {/* <img
              src={eyeIcon}
              alt="show"
              className="text-gray-500 hover:text-blue-500 text-2xl   cursor-pointer"
              onClick={() => {
                setSelectedCoupon(row);

                setShowCoupon(true);
              }}
            /> */}
            <img
              src={editIcon}
              alt="edit"
              className="text-gray-500 hover:text-blue-500 text-2xl cursor-pointer"
              onClick={() => {
                setSelectedCoupon(row);
                setShowFormular(true);
              }}
            />
            {!length && (
              <img
                src={deleteIcon}
                alt="delete"
                className="text-red-500 hover:text-red-800 text-2xl cursor-pointer"
                onClick={() => {
                  setSelectedCoupon(row);
                  setIsOpen(true);
                }}
              />
            )}
          </div>
        );
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "white",
        backgroundColor: "#1F2937",
      },
    },
  };
  useEffect(() => {
    const fetchData = async () => {
      const unsubscribe = Coupon.getAllCoupons(callBack);
      return unsubscribe;
    };

    fetchData();

    return () => {};
  }, []);

  const [payments, setPayments] = useState([]);
  const callBack = (data) => {
    let dataF = [];
    data.forEach(async (coupon) => {
      try {
        // const hasPayments = await visitHasPayments(visit.id);
        // setPayments(
        // payments.push({ visitId: visit.id, hasPayments });
        // );
        // const pointsLength = await fetchvisitPoints(visit.id);
        let couponInstance = new Coupon(coupon);
        // visitInstance.pointsLength = pointsLength;
        dataF.push(couponInstance);
      } catch (error) {
        console.log(error);
      }
    });
    setData(dataF);
    setDisplayedData(dataF);
    setLoding(false);
  };

  const onChange = async (e) => {
    let couponsData = data;
    couponsData = couponsData.filter((coupon) =>
      coupon.code_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setDisplayedData(couponsData);
  };

  return (
    <div className="  px-5 h-full" key="table">
      {open && (
        <SuppDialog
          open={open}
          text={"Êtes-vous sûr de vouloir supprimer ce coupon ?"}
          setIsOpen={setIsOpen}
          setRow={setSelectedCoupon}
          onConfirme={async () => {
            try {
              // First, delete the associated image file from Firebase Storage
              if (selectedCoupon.image) {
                try {
                  await deleteFile(selectedCoupon.image);
                } catch (fileError) {
                  // Log the error but continue with city deletion
                  console.warn("Error deleting city image:", fileError);
                }
              }

              // Then, delete the city from the database
              await selectedCoupon.delete();

              setIsOpen(false);
              setSelectedCoupon(undefined);

              showAlert("La ville a été supprimée avec succès.");
            } catch (e) {
              console.error("Error deleting city:", e);
              showAlert(
                "Une erreur est survenue lors de la suppression de la ville.",
                "error"
              );
            }
          }}
        />
      )}
      {showFormular && (
        <CouponsForm
          setShowFormular={setShowFormular}
          data={selectedCoupon}
          setSelectedCoupon={setSelectedCoupon}
          setdata={setSelectedCoupon}
        />
      )}

      {showCoupon && (
        <ShowCouponsForm
          setShowFormular={setShowCoupon}
          data={selectedCoupon}
          setSelectedCoupon={setSelectedCoupon}
          setdata={setSelectedCoupon}
        />
      )}

      {loding ? (
        <div className="h-40 flex items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="h-full w-full p-7 ">
          <div className="bg-white w-full   lg:p-7 pl-1 rounded-3xl">
            <div className="flex flex-col">
              <div className="mb-4 flex lg:flex-row flex-col justify-between items-center">
                <h1 className="lg:text-3xl md:text-xl text-lg whitespace-nowrap font-semibold leading-tight text-gray-900 ">
                  Coupons
                </h1>
                <div className="flex lg:w-1/3 w-full py-2">
                  <input
                    onChange={onChange}
                    className="bg-white appearance-none border-2 border-amber-500 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-amber-600"
                    id="inline-searcg"
                    type="text"
                    placeholder="Recherche"
                  />
                </div>

                <div className="flex items-center py-2">
                  <button
                    onClick={() => setShowFormular(true)}
                    className="pl-6 pr-1 py-1 border border-transparent text-sm leading-5 font-medium text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:shadow-outline rounded-full flex items-center cursor-pointer"
                  >
                    Créer un coupon
                    <div className="bg-white py-2 px-2 text-amber-500 rounded-full text-center items-center text-lg ml-3">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.2111 0C13.8454 0 15.6154 1.84915 15.6154 4.60109V11.0143C15.6154 13.7662 13.8454 15.6154 11.2111 15.6154H4.40426C1.76999 15.6154 0 13.7662 0 11.0143V4.60109C0 1.84915 1.76999 0 4.40426 0H11.2111ZM11.2111 1.08945H4.40426C2.39097 1.08945 1.08945 2.46723 1.08945 4.60109V11.0143C1.08945 13.1482 2.39097 14.5259 4.40426 14.5259H11.2111C13.2251 14.5259 14.5259 13.1482 14.5259 11.0143V4.60109C14.5259 2.46723 13.2251 1.08945 11.2111 1.08945ZM7.80769 4.5955C8.10838 4.5955 8.35242 4.83953 8.35242 5.14022V7.25571L10.4707 7.25585C10.7713 7.25585 11.0154 7.49989 11.0154 7.80057C11.0154 8.10126 10.7713 8.3453 10.4707 8.3453L8.35242 8.34515V10.4618C8.35242 10.7625 8.10838 11.0065 7.80769 11.0065C7.50701 11.0065 7.26297 10.7625 7.26297 10.4618V8.34515L5.14472 8.3453C4.84331 8.3453 4.6 8.10126 4.6 7.80057C4.6 7.49989 4.84331 7.25585 5.14472 7.25585L7.26297 7.25571V5.14022C7.26297 4.83953 7.50701 4.5955 7.80769 4.5955Z"
                          fill="#FEBD2A"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
              <DataTable
                columns={columns}
                customStyles={customStyles}
                noDataComponent="Aucun résultat trouvé"
                data={displayedData}
                pagination
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CouponsTable;
