// import { Timestamp } from "firebase/firestore";
import {
  createOne,
  deleteOne,
  getAll,
  // getDocuments,
  // getDocumentss,
  updateOne,
} from "../utils/database";
// import { formatDate } from "../utils/date";
import { sendNotification } from "../utils/notification";

class Notification {
  static collectionName = "notifications";

  constructor(id, title, message, date) {
    this.id = id;
    this.title = title;
    this.message = message;
    this.creationDate = date;
  }

  static async create(title, message, tokens) {
    const date = new Date();
    try {
      const docRef = await createOne(this.collectionName, {
        title,
        message,
        creationDate: date,
      });
      const id = docRef.id;
      await updateOne(this.collectionName, id, { id });
      return { title, message, creationDate: date, id };
    } catch (e) {
      throw e;
    }
  }

  static async save(id) {
    await updateOne(Notification.collectionName, id, {
      id: id,
    });
  }

  static getAllNotifications(callBack) {
    return getAll(this.collectionName, callBack);
  }

  static async sendNotification(tokens = []) {
    if (!tokens.length) return;

    console.log("tokens");
    console.log(tokens);
    await sendNotification(this.title, this.message, tokens);
  }

  async delete() {
    await deleteOne(Notification.collectionName, this.id);
  }

  // static getAllNot(query = "") {
  //     return getDocuments(this.collectionName, query);
  // }
}

export default Notification;
