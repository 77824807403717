export const convertMinutesToHMS = (minutes) => {
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = Math.floor(minutes % 60);
  var seconds = Math.floor((minutes % 1) * 60);

  var result = hours + "h " + remainingMinutes + "m " + seconds + "s";
  return result;
};

export const convertSecondsToHMS = (seconds) => {
  var hours = Math.floor(seconds / 3600);
  var remainingMinutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds = Math.floor(seconds % 60);

  var result = hours + "h " + remainingMinutes + "m " + remainingSeconds + "s";
  return result;
};

export const formatDateToString = (dateTimeStr) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateTimeStr).toLocaleDateString("fr", options);
};

export const formatTimeStampToDate = (timestamp) => {
  return timestamp.split("T")[0];
};
