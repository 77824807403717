import React, { useState, useEffect, useRef } from "react";

import { registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import Point from "../models/Point";

import Visit from "../models/Visit";
import MapComponent from "./Map";
import { where } from "firebase/firestore";

import { Spinner } from "@material-tailwind/react";
import { baseImageURL, googleMapsApiKey } from "../api/axiosConfig";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { toogleOverflow } from "../utils/helpers/style_helpers";
import { IoMdClose } from "react-icons/io";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const ShowPointForm = ({ setShowPoint, data, setSelectedPoint, setData }) => {
  //const [image, setImage] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedVisit, setSelectedVisit] = useState(undefined);

  const [showMap, setShowMap] = useState(false);

  const [lat, setLat] = useState(46.76374291690988);
  const [lng, setLng] = useState(2.263165173084807);

  const imageFilePondRef = useRef(null);

  const [showLoader, setShowLoader] = useState(false);

  const containerStyle = {
    width: "100%",
    height: "200px",
  };

  const onChange = async (val) => {
    const data = await Point.getAllPt([where("idVisit", "==", val.value)]);
    let dataF = [];
    data.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const id = doc.id;
      dataF.push(new Point({ id, ...doc.data() }));
    });
    dataF.sort(function (a, b) {
      return a.order - b.order;
    });

    setSelectedVisit(val);
  };

  const formatLink = (link) => {
    // Check if the link is a Google Drive link
    if (link.includes("drive.google.com")) {
      // Extract the file ID from the Google Drive URL
      const fileId = link.match(/[-\w]{25,}/); // Matches the file ID (usually 25 characters long)
      if (fileId) {
        // Format the link to the Google Drive video embed (preview) format
        return `https://drive.google.com/file/d/${fileId[0]}/preview`;
      }
    }

    // Return the link as-is if it's not recognized
    return link;
  };

  const steps = [
    {
      title: "Informations",
      icon: "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    },
    {
      title: "Location",
      icon: "M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z",
    },
    {
      title: "Media",
      icon: "M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v8a2 2 0 01-2 2h-2a2 2 0 01-2-2V6z",
    },
  ];

  useEffect(() => {
    const loadVisits = async () => {
      const querySnapshot = await Visit.getAllVs();
      let myData = [];
      querySnapshot.forEach((doc) => {
        myData.push({ value: doc.id, label: doc.data().name });
      });
      if (data !== undefined) {
        let d = myData.filter((e) => e.value === data.idVisit);
        //setSelectedVisit(d);
        if (d.length > 0) onChange(d[0]);
        setLat(data.coordinates[0]);
        setLng(data.coordinates[1]);
      }
    };
    loadVisits();

    const filePond = imageFilePondRef.current;
    const fetchFile = async (name, isVideo) => {
      if (!name) return;
    };

    if (data) {
      setShowLoader(true);
      filePond?.removeFiles();
      fetchFile(data.image, false);
      if (data.video?.includes("http")) {
        setShowLoader(false);
      } else fetchFile(data.video, true).then(() => setShowLoader(false));
    }

    toogleOverflow();
    return () => {
      toogleOverflow();
    };
  }, []);

  const handleMapClick = (data) => {
    setLat(data.latLng.lat());
    setLng(data.latLng.lng());
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <p className="mt-1 text-lg text-gray-900">{data.name}</p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Visit
              </label>
              <p className="mt-1 text-lg text-gray-900">
                {selectedVisit?.label || "N/A"}
              </p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <p className="mt-1 text-gray-900">{data.description}</p>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <p className="mt-1 text-gray-900">{data.address}</p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Map
              </label>
              <LoadScript
                googleMapsApiKey={googleMapsApiKey}
                libraries={["places"]}
              >
                <GoogleMap
                  mapContainerClassName="w-full h-64 rounded-lg"
                  center={{ lat, lng }}
                  zoom={14}
                >
                  <Marker position={{ lat, lng }} />
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Image
              </label>
              <img
                src={data.image}
                alt={data.name}
                className="w-full h-64 object-cover rounded-lg"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Video
              </label>
              {data.video?.includes("drive") ? (
                <iframe
                  src={formatLink(data.video)}
                  title="Point Video"
                  className="w-full h-64 rounded-lg"
                ></iframe>
              ) : (
                <video controls className="w-full h-64 rounded-lg">
                  <source src={data.video} />
                </video>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-bold text-gray-800">
            Consulter un point
          </h2>
          <button
            onClick={() => {
              setShowPoint(false);
              setData(undefined);
            }}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <IoMdClose />
          </button>
        </div>

        <div className="p-4">
          <div className="flex justify-between mb-8">
            {steps.map((step, index) => (
              <button
                key={step.title}
                onClick={() => setCurrentStep(index)}
                className={`flex flex-col items-center space-y-2 ${
                  currentStep === index ? "text-main" : "text-gray-400"
                }`}
              >
                <div
                  className={`rounded-full p-2 ${
                    currentStep === index ? "bg-main/20" : "bg-gray-100"
                  }`}
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d={step.icon}
                    />
                  </svg>
                </div>
                <span className="text-sm font-medium">{step.title}</span>
              </button>
            ))}
          </div>

          <div className="mt-8">{renderStepContent(currentStep)}</div>

          <div className="mt-8 flex justify-between">
            <button
              onClick={() => setCurrentStep(Math.max(0, currentStep - 1))}
              className={`px-4 py-2 bg-gray-200 text-gray-800 rounded-md ${
                currentStep === 0
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-300"
              }`}
              disabled={currentStep === 0}
            >
              Précédent
            </button>
            <button
              onClick={() =>
                setCurrentStep(Math.min(steps.length - 1, currentStep + 1))
              }
              className={`px-4 py-2 bg-orange-500 text-white rounded-md ${
                currentStep === steps.length - 1
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-orange-600"
              }`}
              disabled={currentStep === steps.length - 1}
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowPointForm;
