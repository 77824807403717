import axios from "./axiosConfig";
import { getStorage, ref, uploadBytes, getDownloadURL , deleteObject } from "firebase/storage";

export const uploadFile = async (
  file,
  folder = "markers",
  onUploadProgress
) => {
  const storage = getStorage();
  const storageRef = ref(storage, `${folder}/${file.name}-${Date.now()}`);

  try {
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);

    // If onUploadProgress is provided, call it with 100% progress
    if (onUploadProgress) {
      onUploadProgress({ loaded: 100, total: 100 });
    }

    return downloadURL;
  } catch (error) {
    console.error("Error uploading file: ", error);
    throw error;
  }
};

export const downloadFromDrive = async (data) => {
  const response = await axios.post("/download_from_drive", data);
  return response;
};

export const getFile = async (imageName, onDownloadProgress) => {
  try {
    const response = await axios.get(`image/${imageName}`, {
      responseType: "blob",
      onDownloadProgress: onDownloadProgress,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteFile = async (fileUrl) => {
  if (!fileUrl) {
    console.warn("No file URL provided for deletion");
    return;
  }

  const storage = getStorage();

  try {
    // Create a reference to the file to delete
    const fileRef = ref(storage, fileUrl);

    // Delete the file
    await deleteObject(fileRef);
    console.log("File deleted successfully");
  } catch (error) {
    console.error("Error deleting file:", error);
    throw error; // Re-throw the error so it can be handled by the calling function
  }
};

export const updateFile = async (
  newFile,
  oldFileUrl,
  folder = "default",
  onUploadProgress
) => {
  const storage = getStorage();

  try {
    // Delete old file if it exists
    if (oldFileUrl) {
      const oldFileRef = ref(storage, oldFileUrl);
      await deleteObject(oldFileRef);
    }

    // Upload new file
    if (newFile) {
      const storageRef = ref(storage, `${folder}/${newFile.name}-${Date.now()}`);
      const snapshot = await uploadBytes(storageRef, newFile);
      
      // Get download URL
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Call progress callback if provided
      if (onUploadProgress) {
        onUploadProgress({ loaded: 100, total: 100 });
      }

      return downloadURL;
    }

    // If no new file is provided, return null or undefined
    return null;
  } catch (error) {
    console.error("Error updating file: ", error);
    throw error;
  }
};
