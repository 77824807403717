import { collection, doc, getDoc } from "firebase/firestore";
import { createOne, getAll, getDocuments } from "../utils/database";
import { db } from "../utils/firebase";
import { Query , where} from "firebase/firestore";
import City from "./City";
import moment from "moment";







class Payment {
    static collectionName = 'payment'

    constructor(amount,  userId) {
        this.amount = amount;
        this.date = new Date().toDateString();
        this.userId = userId;
    }


    static async create(amount,visitorId) {
        try {
            await createOne(this.collectionName, {
                amount,
                visitorId
            });
        } catch (e) {
            throw new Error("Error while creating payment");
        }
    }


    static getAllPayments(callBack) {
        return getAll(this.collectionName, callBack);
    }

    static getAllP(query = "") {
        return getDocuments(this.collectionName, query);
    }


    static async getById(id) {
        const paymentRef = await getDoc(doc(collection(db, "payments"), id));

        if (paymentRef.exists) {
            const paymentData = paymentRef.data();

            return new Payment(
                id,
                paymentData.amount,
                paymentData.date,
                paymentData.userId
            );
        } else {
            throw new Error(`Payment with ID ${id} does not exist`);
        }
    }



    static async getPaymentsByDateRange(startDate, endDate) {
        const payments = await this.getAllP();
        const filteredPayments = payments.docs.filter((payment) => {
            const paymentDate = moment(payment.data().createdAt.toDate()).format("YYYY-MM-DD");
            
            // console.log(paymentDate);
            // console.log(payment.data().createdAt.toDate().toDateString());
            // console.log(paymentDate >= new Date(moment(startDate).format("YYYY-MM-DD")) && paymentDate <= new Date(moment(endDate).format("YYYY-MM-DD")));
            // return paymentDate >= new Date(moment(startDate).format("YYYY-MM-DD")) && paymentDate <= new Date(moment(endDate).format("YYYY-MM-DD"));
            
            return moment(paymentDate).isBetween(moment(moment(startDate).format("YYYY-MM-DD")), moment(moment(endDate).format("YYYY-MM-DD")),undefined, '[]');

        }

        );
        


        return filteredPayments;

    }


    static async getPaymentsByDate(date) {
        const payments = await this.getAllP();
        const filteredPayments = payments.docs.filter((payment) => {
            const paymentDate = moment(payment.data().createdAt.toDate()).format("YYYY-MM-DD");
            // console.log(paymentDate);
            return moment(paymentDate).isSame(moment(moment(date).format("YYYY-MM-DD")));
        }
        );
        return filteredPayments;
    }


}






export default Payment;