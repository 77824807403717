import React, { useState } from "react";
import DataTable from "react-data-table-component";
import LoadingSpinner from "./loadingSpinner";

const CityTable = () => {
  const [loding, setLoding] = useState(false);

  const columns = [
    {
      name: <span className="text-lg font-bold">Nom</span>,
      selector: (row) => {
        return (
          <div className="flex items-center gap-2 py-2 ">
            <span className="bg-gray-100 px-3 py-2 rounded-lg font-semibold text-lg mr-3">
              01
            </span>
            <img
              src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=387&q=80"
              className="w-12 h-12 rounded-full"
              alt=""
            />
            <span className="font-semibold text-sm">Abdelilah Laloui</span>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <span className="text-lg font-bold">Date</span>,
      selector: (row) => (
        <div className="font-semibold text-sm">20-Nov-2020</div>
      ),
      sortable: true,
    },
    {
      name: <span className="text-lg font-bold">Abonnement</span>,
      selector: (row) => (
        <div className="font-semibold text-sm">$0 / 1 months</div>
      ),
      sortable: true,
    },
    {
      name: <span className="text-lg font-bold">Situation</span>,
      selector: (row) => (
        <div className="text-base px-5 py-2.5 text-center bg-yellow-400 rounded-lg text-white">
          Copmleted
        </div>
      ),
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "white",
        backgroundColor: "#1F2937",
      },
    },
  };
  return (
    <div className="w-full" key="table">
      <div className="bg-white max-w-full lg:ml-5 ml-1 mt-8 p-7 lg:pl-10 pl-1 rounded-3xl xl:mr-2 mr-0">
        <div className="flex flex-col">
          <div className="mb-4 flex justify-between items-center">
            <h1 className="lg:text-3xl md:text-xl text-lg font-bold leading-tight text-gray-900">
              Abonnement
            </h1>
          </div>
          <div className=" py-2 sm:px-6 lg:px-8">
            <div className="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b border-gray-200">
              {loding ? (
                <div className="h-40 w-full flex items-center justify-center">
                  <LoadingSpinner />
                </div>
              ) : (
                <DataTable
                  columns={columns}
                  noDataComponent="Aucun résultat trouvé"
                  data={[
                    {
                      nom: "test",
                    },
                  ]}
                  pagination
                  customStyles={customStyles}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CityTable;
