import React, { useEffect, useState } from 'react';
import { auth } from '../../utils/firebase';
import { Auth } from 'firebase/auth';
import SideBareContext from './SideBareContext';

function SideBareProvider({ children }) {
    const [activeRoute, setActiveRoute] = useState('/');

    return <SideBareContext.Provider value={{ activeRoute, setActiveRoute }}>{children}</SideBareContext.Provider>;
}

export default SideBareProvider;