import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import Notification from "../models/Notification";
import AlertContext from "../context/AlertContext/allertContext";
import ReactSelect from "react-select";
import Visit from "../models/Visit";
import User from "../models/User";
import { where } from "firebase/firestore";
import Payment from "../models/payment";
import { sendNotification } from "../utils/notification";
import { toogleOverflow } from "../utils/helpers/style_helpers";

const NotificationForm = ({ setShowFormul }) => {
  const { showAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const error = "";
  const [visits, setVisits] = useState([]);
  const [selectedVisit, setSelectedVisit] = useState();

  const [users, setUsers] = useState([]);
  const [selectUsers, SetSelectedUsers] = useState([]);
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: "",
      message: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Entrer notification title"),
      message: Yup.string().required("Please Entrer notification message"),
    }),
    onSubmit: async (values) => {
      let tokens = [];
      let usersIds = new Set([]);
      if (!loading) {
        setLoading(true);
        const newNotification = await Notification.create(
          values.title,
          values.message
        );

        if ((selectedVisit && selectedVisit.length) || selectUsers?.length) {
          if (selectedVisit && selectedVisit.length)
            await Promise.all(
              await selectedVisit?.map(async ({ value }) => {
                await Payment.getAllP([where("visitsId", "==", value)]).then(
                  async (payments) => {
                    payments?.forEach((payment) => {
                      usersIds.add(payment.data()?.userId);
                    });
                  }
                );
              })
            );

          console.log("newNotification");
          console.log(newNotification.id);
          console.log("newNotification");
          if (selectUsers?.length)
            selectUsers.forEach((user) => {
              usersIds.add(user.value);
            });

          usersIds.forEach(async (id) => {
            const userDoc = await User.getUserById(id);
            const user = new User({
              id: userDoc.id,
              ...userDoc.data(),
            });
            // tokens.push();

            user.notifications.push(newNotification?.id);
            user.notifications = Array.from(new Set(user.notifications));
            await user.save();
            if (user?.tokenFcm)
              await sendNotification(values.title, values.message, [
                user?.tokenFcm,
              ]);
          });
        } else {
          console.log("newNotification");
          console.log(newNotification);
          console.log("newNotification");
          const data = await User.getAll();
          data.docs.forEach(async (doc) => {
            const user = new User({
              id: doc.id,
              ...doc.data(),
            });

            if (!user?.notifications?.includes(newNotification?.id)) {
              user.notifications.push(newNotification?.id);
              await user.save();

              if (user?.tokenFcm) {
                await sendNotification(values.title, values.message, [
                  user?.tokenFcm,
                ]);
              }
            }
            console.log("user");
            console.log(user);
            console.log("user");
          });
        }

        showAlert("La notification a été creé avec succès.");
        setShowFormul(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const loadVisits = async () => {
      const querySnapshot = await Visit.getAllVs();
      let myData = [];
      querySnapshot.forEach((doc) => {
        myData.push({ value: doc.id, label: doc.data().name });
      });
      setVisits(myData);
    };

    const loadUsers = async () => {
      const querySnapshot = await User.getAll();
      let myData = [];
      querySnapshot.forEach((doc) => {
        myData.push({
          value: doc.id,
          label: doc.data().name,
          token: doc.data().tokenFcm,
        });
      });
      setUsers(myData);
    };
    loadUsers();
    loadVisits();

    toogleOverflow();
    return () => {
      toogleOverflow();
    };
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
      action="#"
    >
      <div className="fixed h-full overflow-hidden p-5 z-30 top-0 w-full left-0">
        <div className="flex relative items-center overflow-hidden justify-center  h-full  text-center sm:block  ">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center bg-white rounded-3xl text-left  
            shadow-xl transform transition-all overflow-y-auto max-h-[calc(100%-4rem)]     sm:align-middle sm:max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white  px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  Ajouter de Notification
                </h2>
                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-full cursor-pointer"
                  onClick={() => {
                    setShowFormul(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-7 h-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>

              <label
                className="text-gray-800 block mb-2 font-bold"
                htmlFor="title"
              >
                Campagne
              </label>
              <input
                name="title"
                className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 mt-2 rounded-full"
                placeholder="Entrer le nom"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.title || ""}
                // invalid={
                //     validation.touched.name && validation.errors.name ? true : false
                // }
              />
              {validation.touched.title && validation.errors.title ? (
                <h1 className="text-red-500 font-semibold">
                  {validation.errors.title}
                </h1>
              ) : null}

              <label
                className="text-gray-800 block mb-2 font-bold"
                htmlFor="title"
              >
                Message
              </label>
              <textarea
                name="message"
                className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                placeholder="Entrer le message"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.message || ""}
                // invalid={
                //     validation.touched.name && validation.errors.name ? true : false
                // }
              />
              {validation.touched.message && validation.errors.message ? (
                <h1 className="text-red-500 font-semibold">
                  {validation.errors.message}
                </h1>
              ) : null}

              <div className="mb-3">
                <label
                  className="text-gray-800 block mb-2 font-bold"
                  htmlFor="visits"
                >
                  Visites
                </label>
                <ReactSelect
                  value={selectedVisit}
                  isMulti={true}
                  options={visits}
                  onChange={(val) => {
                    setSelectedVisit(val);
                  }}
                  menuShouldScrollIntoView={true}
                  maxMenuHeight={150}
                  // options={dissortbyMulti}
                  // classNamePrefix="js-example-disabled-multi mb-0"
                  // isDisabled={fa}
                />
              </div>

              <div className="pb-10">
                <label
                  className="text-gray-800 block mb-2 font-bold"
                  htmlFor="users"
                >
                  Utilisateurs
                </label>
                <ReactSelect
                  value={selectUsers}
                  isMulti={true}
                  options={users}
                  onChange={(val) => {
                    SetSelectedUsers(val);
                  }}
                  menuShouldScrollIntoView={true}
                  maxMenuHeight={100}
                />
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 text-center">
              <button
                onClick={() => {
                  setShowFormul(false);
                }}
                type="button"
                className="md:py-3 py-2 md:px-8 px-4 bg-gray-500 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-gray-700 mr-2"
              >
                Annuler
              </button>
              <button
                color="success"
                disabled={error ? null : loading ? true : false}
                className="md:py-3 py-2 md:px-10 px-5 bg-amber-400 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-amber-500"
                type="submit"
              >
                {error ? null : loading ? (
                  <h1 className="text-sm me-2"> Loading... </h1>
                ) : (
                  "Créer"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NotificationForm;
