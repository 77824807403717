import MonumentsCard from "../components/Monuments/MonumentsCard";

const MonumentsPage = () => {
  return (
    <div className="w-full px-4">
      <MonumentsCard />
    </div>
  );
};

export default MonumentsPage;
