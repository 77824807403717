import axios from 'axios';
import User from '../models/User';
import { getMessaging } from 'firebase/messaging';


export const sendNotification = async (title, message, tokens) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer AAAA6eV4Ac0:APA91bE4Iepitp7FQACJMRQ6TrDhOgJDwU-FPJLSz1o15X__I7DtUvDI5dBTqXfWCc9x514iDKkHMNKMmUIoyUHEjUrVwh-d0i6JoHhFff4YqcfAlROe44qbjFMKCB5tyMzUOcdl8x-y'
            'Authorization': 'Bearer AAAA9JapyNE:APA91bHJxVr1iBqVuEe8aRnU9HADr0obQKN8T5uTe55qK12IbZGhcA6P8Wmlu2BVDS93hn1sMCsIMCrepmEGhhR9X2ldfbCEL5OOd1rMEUHPn30Vygg0WGtMDv3hn0n4gyBTW4FSLMiE'
        }
        	
        
        tokens.forEach(async (token) => {
            const payload = {
                'notification': {
                    title: title,
                    body: message,
                },
                'to': token,
            };
            await axios.post('https://fcm.googleapis.com/fcm/send', payload, {
                headers: headers
            }).then(data => { console.log(data) }).catch(e => console.log(e));
        });

        console.log('Notification sent successfully to all users!');
    } catch (error) {
        console.error('Error sending notification:', error);
    }
};


