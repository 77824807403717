import React from "react";
import VisitsTable from "../components/visitsTable";
// import Visit from "../models/Visit";

const VisitsPage = () => {
  return (
    <div className="w-full">
      <VisitsTable />
    </div>
  );
};

export default VisitsPage;
