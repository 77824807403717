import React from "react";

const LoadingSpinner = () => {
  return (
    // <div className="flex items-center justify-center space-x-2">
    //   <div className="w-4 h-4 rounded-full animate-pulse bg-amber-400"></div>
    //   <div className="w-4 h-4 rounded-full animate-pulse bg-amber-400"></div>
    //   <div className="w-4 h-4 rounded-full animate-pulse bg-amber-400"></div>
    // </div>

    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingSpinner;
